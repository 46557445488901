export const LEFT_ALIGN = {
    paddingLeft: '8px',
    paddingRight: '25px'
};

export const BUTTON_STYLE = {
    background: "#FAFAFA",
    border: "1px solid rgb(220, 220, 220)",
    padding: '0px 13px',
    fontSize: '12px',
    borderRadius: '4px',
    color: "#F11286",
    textDecoration: 'none',
    display: "inline-block",
    lineHeight: '24px',
    height: '26px',
    verticalAlign: 'top'
};

export const DATATABLE_PROPS = {
    ADD_BUTTON_STYLE: {
        background: "#FAFAFA",
        border: "1px solid rgb(220, 220, 220)",
        padding: '0px 13px',
        fontSize: '12px',
        borderRadius: '4px',
        color: "#F11286",
        textDecoration: 'none',
        display: "inline-block",
        lineHeight: '28px',
        verticalAlign: 'top'
    },
    PAGINATEION_STYLE: {
        pagination: {
            marginTop: "5px",
            justifyContent: "flex-end",
            display: "flex"
        },
        button: {
            height: '32px',
            color: 'rgba(0, 0, 0, 0.87)',
            border: '1px solid rgb(220, 220, 220)',
            backgroundColor: 'white'
        },
        active: {
            color: "#ffffff",
            backgroundColor: '#F11286',
            border: '1px solid #F11286'
        },
        inactive: {
            backgroundColor: '#fff',
            border: '1px solid rgb(220, 220, 220)'
        },
        hoverColor: '#F11286'
    },
    ROW_PER_PAGE: 10,
};