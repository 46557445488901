import { useState } from "react"
import { useNavigate } from "react-router-dom";
import { login } from "../../../shared/services/AuthHelper"
import { JkcInput } from '@softsmart/jkc-input';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../authConfig";
import { toast } from 'react-toastify';

const LoginComponent = () => {
    const navigate = useNavigate();
    const [UserName, setUserName] = useState('')
    const [Password, setPassword] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    // const rememberMeRef = React.useRef(false)

    const inputStyle = {
        borderColor: "#DEE2E6",
        borderRadius: '4px',
        padding: "0px 7px 0px 7px",
        height: '40px',
        fontSize: "14px",
        background: 'transparent',
        color: "#fff"
    }

    const labelStyle = {
        color: "#F11286",
        paddingLeft: '7px',
        fontSize: "14px",
        fontWeight: "500"
    }

    const [settings, setSettings] = useState<any>({
        label: "",
        value: "",
        className: "",
        placeholder: "",
        mask: "(999)-999-9999",
        maskChar: "#",
        alwaysShowMask: true,
        iconShow: true,
        iconShowHideToggle: false,
        isNormalLabel: true,
        hideLabel: false,
        inputStyle: inputStyle,
        labelStyle: labelStyle
    });

    const auth = async () => {
        try {
            setIsLoading(true)
            const data = await login({ accessToken: '', Password, UserName, setRedirectToReferrer: () => {} });
            // console.log({ accessToken: '', Password, UserName, setRedirectToReferrer: () => {} });
            console.log('login',data);
            if (data && data.status) {
                navigate('/dashboard', { replace: true });
            } else {
                toast.error(data.message);
            }
            // 
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
        }
    }
    const { instance } = useMsal();

    const handleLogin = (loginType: string) => {
        if (loginType === "popup") {
            instance.loginPopup(loginRequest).catch((e:any) => {
                console.log(e)
            });
        } else if (loginType === "redirect") {
            instance.loginRedirect(loginRequest).catch((e:any) => {
                console.log(e)
            });
        }
    }

    const handleLogout = (logoutType: string) => {
        if (logoutType === "popup") {
            instance.logoutPopup({
                mainWindowRedirectUri: "/"
            });
        } else if (logoutType === "redirect") {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/"
            });
        }
    }

    return (
        <div className="auth-wrapper" onKeyUp={(e) => e.key === 'Enter' && auth()}>
            <div className="row align-items-center">
                <div className="col-md-12">
                    <div className="card-body" style={{width: 325}}>
                        <div className="text-center">
                            <img src="../assets/img/logo.png" alt="LetsConnect" width={180} />
                            <h4 className="mb-1 mt-4 fut-std" style={{fontSize: 34}}>Welcome</h4>
                            <div className="pinkline"></div>
                            <div className="mb-5 f-w-400 seg-ui mt-4" style={{fontSize: 14}}>Sign in by enterning information below</div>
                        </div>
                        <div className="input-group mb-3">
                            <JkcInput type={'username'} name={"UserName"} settings={{...settings, value: UserName, label: "Username"}} onChangeEvent={(e) => setUserName(e.target ? e.target.value: '')}></JkcInput>
                        </div>
                        <div className="input-group mb-4">
                            <JkcInput type={'password'} name={"Password"} settings={{...settings, value: Password, label: "Password"}} onChangeEvent={(e) => setPassword(e.target.value)}></JkcInput>
                        </div>
                        <button type="button" onClick={auth} disabled={isLoading} className="btn btn-block btn-pink w-100" >Sign In</button>
                        {/* <UnauthenticatedTemplate>
                            <div className="my-2 text-center"> OR </div>
                            <button type="button" onClick={() => handleLogin("redirect")} className="btn btn-block btn-pink mb-4 w-100"> <i className="fa-brands fa-microsoft me-2"></i> Sign in with Microsoft</button>
                        </UnauthenticatedTemplate>
                        <AuthenticatedTemplate>
                            <button type="button" onClick={() => handleLogout("redirect")} className="btn btn-block btn-pink mb-4 w-100" >Sign Out</button>
                        </AuthenticatedTemplate> */}
                        {/* <p className="mb-0 text-muted">© Admin Panel All Right Reserved 2022</p> */}
                    </div>
                </div>
            </div>
            <div style={{position: 'absolute', left: 0, bottom: 200}}>
                <h3 className="attraction">Attraction and chemistry</h3>
                <div className="appkeys">are the keys to this App</div>
            </div>
        </div>
    );

}
export default LoginComponent;