import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Modal, Button } from 'react-bootstrap';

interface Props {
  payload: any,
  isOpen: boolean;
  callBack: any;
  apiUrl: any;
  apiHeaders: any;
  modelName: any,
  buttonStyle?: any;
  modelStyle?: any;
  showModal?: any;
}

export const defaultModalStyle = {
  header: { padding: '10px 15px 10px 15px' },
  title: { fontSize: '15px' },
  body: {},
  button: {},
  footer: {padding: '5px'}
}

const DeleteModal = ({
  payload,
  isOpen = false,
  apiUrl,
  showModal,
  apiHeaders,
  modelName,
  modelStyle = {},
  callBack = () => { },
}: Props) => {

  const [show, setShow] = React.useState(isOpen);
  modelStyle = { ...defaultModalStyle, ...modelStyle };
  const handleDelete = async () => {
    try {
      const { data } = await axios.post(apiUrl, payload, {
          headers: apiHeaders
        }
      )
      if (data) {
        setShow(false);
        toast.success(data.info);
        callBack(true);
      } else {
        toast.error("Error deleting data!");
      }
    } catch (error: any) {
      toast.error("Error deleting data!");
    }
  };

  const handleClose = () => {
    setShow(false);
    callBack(false);
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header style={modelStyle.header} closeButton>
        <Modal.Title style={modelStyle.title}>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body style={modelStyle.body}><p className='mb-0'>Are you sure you want to delete this item?</p></Modal.Body>
      <Modal.Footer style={modelStyle.footer}>
        <Button style={modelStyle.button} variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button style={modelStyle.button} variant="danger" onClick={handleDelete}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteModal