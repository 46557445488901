import { Button } from "react-bootstrap";
import { BASE_API, SERVICES } from "../../shared/services/RouteConst";
import { toast } from 'react-toastify';
import axios from "axios";

const ClearData = () => {


    const clearData = async () => {
        const data = await axios.post(`${BASE_API}${SERVICES}/v1/data/execute`,
            {
                DbName: 'DbLetsConnect',
                QueryOrProcedureName: "MEMBER_TestingOnlyFlushConnections",
                Params: [
                    {
                        Name: "MemberId1",
                        Value: "61569"
                    },
                    {
                        Name: "MemberId2",
                        Value: "61566"
                    }
                ],
            },
            {
                headers: {
                    "AppName": "DbLetsConnect",
                    "Authorization": "Bearer 14FE34B2-9547-43F5-A57C-F0DC7DE81AA9",
                    "Content-Type": "application/json"
                },
            },
        )
        if (data && data.status === 200) {
            toast.success("Data has been cleared for Micheal and Tia");
        } else {
            toast.error("Something went wrong !");
        }
    };
    return (
        <div className="clear-btn">
            <Button variant="primary" className="btn-pink" size="lg" onClick={clearData}>Clear</Button>
        </div>
    );
};

export default ClearData;