import Header from "./Header";
import Sidebar from "./Sidebar"
import Footer from "./Footer"

const AdminLayout = ({ component }: { component: any }) => (
    <div className="page-header-fixed">
        <Header />
        <div id="content" className="content pb-5">
            <div className="pc-container">
                {component}
            </div>
        </div>
        <Footer />
    </div>
);

export default AdminLayout;  