import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as faIcon from '@fortawesome/free-solid-svg-icons';

export const BUTTON_STYLE = {
    padding: '0px 13px',
    fontSize: '12px',
    borderRadius: '4px',
    textDecoration: 'none',
    display: "inline-block",
    lineHeight: '28px',
    verticalAlign: 'top'
};

export const DETAIL_PAGES_STYLE = {
    BUTTON_STYLE: {
        background: "#FAFAFA",
        border: "1px solid rgb(220, 220, 220)",
        padding: '0px 13px',
        fontSize: '12px',
        borderRadius: '4px',
        color: "#F11286",
        textDecoration: 'none',
        display: "inline-block",
        lineHeight: '28px',
        verticalAlign: 'top'
    },
    TAB_STYLE: {
        borderColor: "#F11286",
        activeColor: "#ffffff",
        inactiveColor: "#000000",
        fontSize: "",
        padding: ""
    },
    CUSTOM_STYLE: {
        NOTE_STYLE: {
            color: "#5e5e5e",
            toolbarColor: "#d7d8e1",
            fontColor: '#7a7e79',
            userFontColor: '#000c40d9',
            userBackColor: "#e8eef35e",
            backColor: "#e7e7e7",
            userBorder: '10px solid #cdd1d5',
            noUserBorder: '10px solid #7a7e79',
            fontSize: "14px",
            labelStyle: {
                marginBottom: "10px",
                color: "#000",
                paddingLeft: "7px",
                fontSize: "16px"
            }
        },
        labelStyle: {
            color: "#f11286",
            paddingLeft: '7px',
            fontSize: "13px",
            fontWeight: "500"
        }
    }
};

export const DATATABLE_PROPS = {
    ADD_BUTTON_STYLE: {
        background: "#FAFAFA",
        border: "1px solid rgb(220, 220, 220)",
        padding: '0px 13px',
        fontSize: '12px',
        borderRadius: '4px',
        color: "#F11286",
        textDecoration: 'none',
        display: "inline-block",
        lineHeight: '28px',
        verticalAlign: 'top'
    },
    ACTION_BUTTON_STYLE: {
        background: "#FAFAFA",
        border: "1px solid rgb(220, 220, 220)",
        padding: '3px 10px',
        fontSize: '9pt',
        borderRadius: '4px',
        color: "#F11286",
        textDecoration: 'none',
        display: "inline-block",
        lineHeight: '16px',
        marginLeft: '3px',
        marginRight: '3px'
    },
    PAGINATEION_STYLE: {
        pagination: {
            marginTop: "5px",
            justifyContent: "flex-end",
            display: "flex"
        },
        button: {
            height: '32px',
            color: 'rgba(0, 0, 0, 0.87)',
            border: '1px solid #ccc',
            backgroundColor: 'white'
        },
        active: {
            backgroundColor: '#F11286',
            border: '1px solid #F11286',
            color: "#ffffff"
        },
        inactive: {
            backgroundColor: '#FFFFFF'
        },
        hoverColor: '#F11286'
    },
    ROW_PER_PAGE: 30,
};

export const ADMIN_PAGES_COLUMNS = {
    menu: [
        {
            name: 'Name',
            selector: (row: any) => row.Name,
            sortable: true,
        },
        {
            cell: (row: any) => {
                return (<i className={'fas ' + row.Icon}></i>)
            },
            name: "Icon",
            center: true,
            width: '60px',
        },
        {
            name: 'Color',
            cell: (row: any) => {
                return (<span style={{ backgroundColor: row.Color, height: 20, width: 20 }}></span>)
            },
            center: true,
            width: '60px',
        },
        {
            name: 'Page Path',
            selector: (row: any) => row.PagePath,
            sortable: true
        },
        {
            name: 'Applications',
            selector: (row: any) => row.AppName,
            sortable: true
        },
        {
            cell: (row: any) => {
                return (
                    [{ name: 'Edit', action: 'edit' }, { name: 'Delete', action: 'delete' }].map((res: any, key: any) => {
                        return (
                            <button
                                key={key}
                                onClick={() => { row.buttonClick(row, res.action) }}
                                style={DATATABLE_PROPS.ACTION_BUTTON_STYLE}>{res.name}</button>
                        );
                    })
                )
            },
            name: "Action",
            style: { paddingRight: '0px !important' },
            width: '130px',
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        }
    ],
    subMenu: [
        {
            name: 'Name',
            selector: (row: any) => row.Name,
            sortable: true
        },
        {
            cell: (row: any) => {
                return (<i className={'fas ' + row.Icon}></i>)
            },
            name: "Icon",
            center: true,
            width: '60px',
        },
        {
            name: 'Color',
            cell: (row: any) => {
                return (<span style={{ backgroundColor: row.Color, height: 20, width: 20 }}></span>)
            },
            center: true,
            width: '60px',
        },
        {
            name: 'Page Path',
            selector: (row: any) => row.PagePath,
            sortable: true
        },
        {
            cell: (row: any) => {
                return (
                    [{ name: 'Edit', action: 'edit' }, { name: 'Delete', action: 'delete' }].map((res: any, key: any) => {
                        return (
                            <button
                                key={key}
                                onClick={() => { row.buttonClick(row, res.action) }}
                                style={DATATABLE_PROPS.ACTION_BUTTON_STYLE}>{res.name}</button>
                        );
                    })
                )
            },
            name: "Action",
            style: { paddingRight: '0px !important' },
            width: '130px',
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        }
    ],
    role: [
        {
            name: 'Name',
            selector: (row: any) => row.Name,
            sortable: true,
            width: '250px',
        },
        {
            name: 'Description',
            selector: (row: any) => row.Description,
            sortable: true
        },
        {
            name: 'Application Name',
            selector: (row: any) => row.ApplicationName,
            sortable: true,
            width: '250px',
        },
        {
            name: 'Total of Users',
            selector: (row: any) => row.NoOfUsers,
            sortable: true,
            width: '140px',
        },
        {
            cell: (row: any) => {
                return (
                    [{ name: 'Edit', action: 'edit' }, { name: 'Delete', action: 'delete' }].map((res: any, key: any) => {
                        return (
                            <button
                                key={key}
                                onClick={() => { row.buttonClick(row, res.action) }}
                                style={DATATABLE_PROPS.ACTION_BUTTON_STYLE}>{res.name}</button>
                        );
                    })
                )
            },
            name: "Action",
            style: { paddingRight: '0px !important' },
            width: '130px',
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        }
    ],
    applications: [
        {
            name: 'Name',
            selector: (row: any) => row.Name,
            sortable: true
        },
        {
            name: 'Title',
            selector: (row: any) => row.Title,
            sortable: true
        },
        {
            cell: (row: any) => {
                return (
                    [{ name: 'Edit', action: 'edit' }, { name: 'Delete', action: 'delete' }].map((res: any, key: any) => {
                        return (
                            <button
                                key={key}
                                onClick={() => { row.buttonClick(row, res.action) }}
                                style={DATATABLE_PROPS.ACTION_BUTTON_STYLE}>{res.name}</button>
                        );
                    })
                )
            },
            name: "Action",
            style: { paddingRight: '0px !important' },
            width: '130px',
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        }
    ],
    lookup: [
        {
            name: 'Name',
            selector: (row: any) => row.Name,
            sortable: true,
            width: '250px',
        },
        {
            name: 'Description',
            selector: (row: any) => row.Description,
            sortable: true
        },
        {
            name: 'DatabaseName',
            selector: (row: any) => row.DatabaseName,
            sortable: true,
            width: '250px',
        },
        {
            cell: (row: any) => {
                return (
                    [{ name: 'Edit', action: 'edit' }, { name: 'Delete', action: 'delete' }].map((res: any, key: any) => {
                        return (
                            <button
                                key={key}
                                onClick={() => { row.buttonClick(row, res.action) }}
                                style={DATATABLE_PROPS.ACTION_BUTTON_STYLE}>{res.name}</button>
                        );
                    })
                )
            },
            name: "Action",
            style: { paddingRight: '0px !important' },
            width: '130px',
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        }
    ],
    lookupTypes: [
        {
            name: 'Code',
            selector: (row: any) => row.Code,
            sortable: true
        },
        {
            name: 'Description',
            selector: (row: any) => row.Description,
            sortable: true
        },
        {
            cell: (row: any) => {
                return (
                    [{ name: 'Edit', action: 'edit' }, { name: 'Delete', action: 'delete' }].map((res: any, key: any) => {
                        return (
                            <button
                                key={key}
                                onClick={() => { row.buttonClick(row, res.action) }}
                                style={DATATABLE_PROPS.ACTION_BUTTON_STYLE}>{res.name}</button>
                        );
                    })
                )
            },
            name: "Action",
            style: { paddingRight: '0px !important' },
            width: '130px',
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        }
    ],
    properties: [
        {
            name: 'GroupName',
            selector: (row: any) => row.GroupName,
            sortable: true,
            width: '250px',
        },
        {
            name: 'Name',
            selector: (row: any) => row.PropertyName,
            sortable: true,
            width: '250px',
        },
        {
            name: 'Value',
            selector: (row: any) => row.Value,
            sortable: true
        },
        {
            cell: (row: any) => {
                return (
                    [{ name: 'Edit', action: 'edit' }, { name: 'Delete', action: 'delete' }].map((res: any, key: any) => {
                        return (
                            <button
                                key={key}
                                onClick={() => { row.buttonClick(row, res.action) }}
                                style={DATATABLE_PROPS.ACTION_BUTTON_STYLE}>{res.name}</button>
                        );
                    })
                )
            },
            name: "Action",
            style: { paddingRight: '0px !important' },
            width: '130px',
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        }
    ],
    model: [
        {
            name: 'Name',
            selector: (row: any) => row.Name,
            sortable: true
        },
        {
            name: 'Database Name',
            selector: (row: any) => row.DbName,
            sortable: true
        },
        {
            name: 'Schema',
            selector: (row: any) => row.DbSchema,
            sortable: true
        },
        {
            name: 'DbObject',
            selector: (row: any) => row.DbObject,
            sortable: true
        },
        {
            cell: (row: any) => {
                return (
                    [{ name: 'Edit', action: 'edit' }, { name: 'Delete', action: 'delete' }].map((res: any, key: any) => {
                        return (
                            <button
                                key={key}
                                onClick={() => { row.buttonClick(row, res.action) }}
                                style={DATATABLE_PROPS.ACTION_BUTTON_STYLE}>{res.name}</button>
                        );
                    })
                )
            },
            name: "Action",
            style: { paddingRight: '0px !important' },
            width: '130px',
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        }
    ],
    user: [
        {
            name: 'Email',
            selector: (row: any) => row.Email,
            sortable: true
        },
        {
            name: 'Phone',
            selector: (row: any) => row.Phone,
            sortable: true,
            width: '130px'
        },
        {
            name: 'First Name',
            selector: (row: any) => row.FirstName,
            sortable: true,
        },
        {
            name: 'Last Name',
            selector: (row: any) => row.LastName,
            sortable: true,
        },
        {
            name: 'Role Name',
            selector: (row: any) => row.RoleName,
            sortable: true,
            width: '150px'
        },
        {
            cell: (row: any) => {
                return (<FontAwesomeIcon icon={faIcon['faCoffee']} />)
            },
            name: "Icon",
            center: true,
            style: { paddingRight: '0px !important' },
            width: '60px',
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        },
        {
            cell: (row: any) => {
                return (
                    [{ name: 'Edit', action: 'edit' }, { name: 'Delete', action: 'delete' }].map((res: any, key: any) => {
                        return (
                            <button
                                key={key}
                                onClick={() => { row.buttonClick(row, res.action) }}
                                style={DATATABLE_PROPS.ACTION_BUTTON_STYLE}>{res.name}</button>
                        );
                    })
                )
            },
            name: "Action",
            style: { paddingRight: '0px !important' },
            width: '130px',
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        }
    ],
    members: [
        {
            name: 'Member Id',
            selector: (row: any) => row.MemberId,
            sortable: true
        },
        {
            name: 'First Name',
            selector: (row: any) => row.FirstName,
            sortable: true
        },
        {
            name: 'Last Name',
            selector: (row: any) => row.LastName,
            sortable: true
        },
        {
            name: 'Username',
            selector: (row: any) => row.UserName,
            sortable: true
        },
        {
            name: 'City',
            selector: (row: any) => row.City,
            sortable: true
        },
        {
            name: 'Email',
            selector: (row: any) => row.Email,
            sortable: true
        },
        {
            name: 'Phone',
            selector: (row: any) => row.Phone,
            sortable: true
        },
        {
            name: 'Date Of Birth',
            selector: (row: any) => row.DateOfBirth,
            sortable: true
        },
        {
            cell: (row: any) => {
                return (
                    [{ name: 'Edit', action: 'edit' }, { name: 'Delete', action: 'delete' }].map((res: any, key: any) => {
                        return (
                            <button
                                key={key}
                                onClick={() => { row.buttonClick(row, res.action) }}
                                style={DATATABLE_PROPS.ACTION_BUTTON_STYLE}>{res.name}</button>
                        );
                    })
                )
            },
            name: "Action",
            style: { paddingRight: '0px !important' },
            width: '130px',
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        }
    ],
    advertisers: [
        {
            name: 'Advertiser Id',
            selector: (row: any) => row.MEMBER_ADS_ID,
            sortable: true
        },
        {
            name: 'Name',
            selector: (row: any) => row.Name,
            sortable: true
        },
        {
            name: 'Duration',
            selector: (row: any) => row.Duration,
            sortable: true
        },
        {
            name: 'Price',
            selector: (row: any) => row.Price,
            sortable: true
        },
        {
            name: 'Url',
            selector: (row: any) => row.Url,
            sortable: true
        },
        {
            cell: (row: any) => {
                return (
                    [{ name: 'Edit', action: 'edit' }, { name: 'Delete', action: 'delete' }].map((res: any, key: any) => {
                        return (
                            <button
                                key={key}
                                onClick={() => { row.buttonClick(row, res.action) }}
                                style={DATATABLE_PROPS.ACTION_BUTTON_STYLE}>{res.name}</button>
                        );
                    })
                )
            },
            name: "Action",
            style: { paddingRight: '0px !important' },
            width: '130px',
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        }
    ],
    dashboard:[
        {
            name: 'State',
            selector: (row: any) => row.Name,
            sortable: true
        },
        {
            name: 'City',
            selector: (row: any) => row.Duration,
            sortable: true
        },
        {
            name: 'No. Of User',
            selector: (row: any) => row.Price,
            sortable: true
        },
        {
            name: 'Male',
            selector: (row: any) => row.Url,
            sortable: true
        },
        {
            name: 'Female',
            selector: (row: any) => row.Url,
            sortable: true
        },
        {
            name: 'Total Revenue',
            selector: (row: any) => row.Url,
            sortable: true
        }
    ]
};