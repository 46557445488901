import React, { useEffect, useState } from "react";
import { Doughnut } from 'react-chartjs-2';
import { Retrieve } from "../../../utils/ApiService";
import CustomTable from '../../../components/CustomTable';
import SubHeader from "../../layout/admin/SubHeader";
import { ADMIN_PAGES_COLUMNS, DATATABLE_PROPS } from "../../../utils/DatatableProps";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../../shared/services/RouteConst";

export const defaultDatatableProps = {
    apiHeaders: { 'Authorization': 'bearer 14FE34B2-9547-43F5-A57C-F0DC7DE81AA9', 'appName': 'dbAdmin' },
    modelName: 'Member',
}
const DashboardComponent = () => {
    const [memberTotalBySex, setMemberTotalBySex] = useState({
        MaleCount: 0,
        FemaleCount: 0,
        TotalCount: 0
    });
    const [memberTotalBySubscription, setMemberTotalBySubscription] = useState({
        PerMonth: 0,
        SixMonth: 0,
        ThreeMonth: 0,
        TotalActiveSubsriber: 0,
        TotalCount: 0,
        TotalFemaleSubsriber: 0,
        TotalMaleSubsriber: 0,
        TotalNewSubsribers: 0
    });
    const [memberTotalByAdvertisers, setMemberTotalByAdvertisers] = useState({
        TotalCount: 0,
        TotalNewSubsribers: 0
    });

    const getCount = React.useCallback(async () => {
        const { error: apiError1, data: memberTotalBySexData }: any = await Retrieve({
            params: {
                DbName: "DbLetsConnect",
                QueryOrProcedureName: "MEMBER_Total_BySex",
                Params: {
                    // MemberId: Id
                }
            },
            apiUrl: `${BASE_URL}Data/ExecuteOld`,
            token: localStorage.getItem('AdminToken')
        });
        const { error: apiError2, data: memberTotalBySubscriptionData }: any = await Retrieve({
            params: {
                DbName: "DbLetsConnect",
                QueryOrProcedureName: "MEMBER_Total_BySubscription",
                Params: {
                    // MemberId: Id
                }
            },
            apiUrl: `${BASE_URL}Data/ExecuteOld`,
            token: localStorage.getItem('AdminToken')
        });
        const { error: apiError3, data: memberTotalByAdvertisersData }: any = await Retrieve({
            params: {
                DbName: "DbLetsConnect",
                QueryOrProcedureName: "MEMBERADS_Total_Advertisers",
                Params: {
                    // MemberId: Id
                }
            },
            apiUrl: `${BASE_URL}Data/ExecuteOld`,
            token: localStorage.getItem('AdminToken')
        });
        await setMemberTotalBySex(memberTotalBySexData[0])
        await setMemberTotalBySubscription(memberTotalBySubscriptionData[0])
        await setMemberTotalByAdvertisers(memberTotalByAdvertisersData[0])

    }, []);

    useEffect(() => {
        getCount();
    }, [])

    const data = {
        labels: [
            '1 month', 
            '3 month', 
            '6 month'
        ],
        datasets: [
            {
                data: [
                    memberTotalBySubscription.PerMonth, 
                    memberTotalBySubscription.ThreeMonth, 
                    memberTotalBySubscription.SixMonth],
                backgroundColor: [
                    '#353535',
                    '#959BA5',
                    '#F11286',
                ],
                borderColor: [
                    '#353535',
                    '#959BA5',
                    '#F11286',
                ],
                borderWidth: 1,
            },
        ],
    };

    const dataHalf = {
        labels: ['Revenue', 'Remaining'],
        datasets: [
            {
                data: [70, 30],
                backgroundColor: [
                    '#F11286',
                    '#F5F5F5'
                ],
                borderColor: [
                    '#F11286',
                    '#F5F5F5'

                ],
                borderWidth: 1,
            },
        ],
    };

    const dataSex = {
        labels: ['Male', 'Female'],
        datasets: [
            {
                data: [memberTotalBySex.MaleCount, memberTotalBySex.FemaleCount],
                backgroundColor: [
                    '#F11286',
                    '#353535'
                ],
                borderColor: [
                    '#F11286',
                    '#353535'
                ],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                display: false
            }
        },
        rotation: 0,
        circumference: 360,
        cutout: "50%",
        maintainAspectRatio: true,
        responsive: true,
        height:150
    };

    const optionsHalf = {
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                enabled: false
            }
        },
        rotation: -90,
        circumference: 180,
        cutout: "75%",
        maintainAspectRatio: true,
        responsive: true
    };

    return (
        <div className="p-3">
            <h6 className="mb-2 ps-1 d-inline-block">Dashboard</h6>
            <Link to={'/Admin/Member/New'} style={DATATABLE_PROPS.ADD_BUTTON_STYLE} className='btn btn-theme-default btn-sm float-end'>
                   Add Member Profile
            </Link>
            <div className="first-section clear mt-2">
                <div className="tiles-box-section">
                    <div className="inner-box-section">
                        <div className="number-charts position-relative">
                            <span className="info-item">New subscribers</span>
                            <span className="num-amount">{memberTotalBySubscription.TotalNewSubsribers}</span>
                            <img src="/assets/img/black_back.png" className="img-fluid card-img"></img>
                        </div>
                        <div className="number-charts position-relative">
                            <span className="info-item">New subscribers revenue</span>
                            <span className="num-amount">{'$' + memberTotalBySubscription.TotalNewSubsribers * 20}</span>
                            <img src="/assets/img/pink_back.png" className="img-fluid card-img"></img>
                        </div>
                    </div>
                    <div className="inner-box-section">
                        <div className="number-charts position-relative">
                            <span className="info-item">Total subscribers</span>
                            <span className="num-amount">{memberTotalBySubscription.TotalCount}</span>
                            <img src="/assets/img/black_back.png" className="img-fluid card-img"></img>
                        </div>
                        <div className="number-charts position-relative">
                            <span className="info-item">Total subscribers revenue</span>
                            <span className="num-amount">{'$' + memberTotalBySubscription.TotalCount * 20}</span>
                            <img src="/assets/img/pink_back.png" className="img-fluid card-img"></img>
                        </div>
                    </div>
                    <div className="inner-box-section">
                        <div className="number-charts position-relative">
                            <span className="info-item">Number of advertisers</span>
                            <span className="num-amount">{memberTotalByAdvertisers.TotalNewSubsribers}</span>
                            <img src="/assets/img/black_back.png" className="img-fluid card-img"></img>
                        </div>
                        <div className="number-charts position-relative">
                            <span className="info-item">Advertisers revenue</span>
                            <span className="num-amount">{'$' + memberTotalByAdvertisers.TotalNewSubsribers * 20}</span>
                            <img src="/assets/img/pink_back.png" className="img-fluid card-img"></img>
                        </div>
                    </div>
                </div>
                <div className="chart-box-section1">
                    <div className="card">
                        <div className="card-body">
                        <div className="d-flex">
                            <div className="w-78">
                                <div className="cpink b6">Total members</div>
                                <div className="cbg b6 f14">By sex</div>
                            </div>
                            <div className="w-22">
                                <div className="f13"><span className="bg-round bg-free"></span>Male</div>
                                <div className="f13"><span className="bg-round bg-1"></span>Female</div>       
                            </div>
                        </div>
                           
                            <div className="row align-items-center mt-3">
                                <div className="col-md-12 px-5">
                                    <div className="full-donut">
                                        <Doughnut data={dataSex} options={options} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="chart-box-section2">
                <div className="card">
                    <div className="card-body bg-1 card-brd-rd">
                        <div className="d-flex">
                            <div className="w-50">
                                <div className="cwhite b6">Subscribers Revenue</div>
                                <div className="cbg b6 f14 text-white">$0</div>
                            </div>
                            <div className="w-50 text-end">
                                <div className="cwhite b6">Advertisers Revenue</div>
                                <div className="cbg b6 f14 text-white">$0</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 px-5">
                                <div className="half-donut">
                                    <Doughnut data={dataHalf} options={optionsHalf} />
                                    <div className="half-donut-amount">
                                        <div style={{ fontSize: "40px" }}>72%</div>
                                        <div>Revenue</div>
                                        <div>$0</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="first-section">
                 <div className="tiles-box-section">
                    <div className="inner-box-section">
                        <div className="number-charts position-relative">
                            <span className="info-item">Monthly subscribers</span>
                            <span style={{textAlign: 'end', paddingRight: '1rem'}} className="num-amount">{memberTotalBySubscription.PerMonth}</span>
                            <img src="/assets/img/black_back.png" className="img-fluid card-img"></img>
                        </div>
                        <div className="number-charts position-relative">
                            <span className="info-item">Monthly subscribers revenue</span>
                            <span style={{textAlign: 'end', paddingRight: '1rem'}} className="num-amount">{'$' + memberTotalBySubscription.PerMonth * 29.99}</span>
                            <img src="/assets/img/pink_back.png" className="img-fluid card-img"></img>
                        </div>
                    </div>
                    <div className="inner-box-section">
                        <div className="number-charts position-relative">
                            <span className="info-item">3 Month subscribers</span>
                            <span style={{textAlign: 'end', paddingRight: '1rem'}} className="num-amount">{memberTotalBySubscription.ThreeMonth}</span>
                            <img src="/assets/img/black_back.png" className="img-fluid card-img"></img>
                        </div>
                        <div className="number-charts position-relative">
                            <span className="info-item">3 Month subscribers revenue</span>
                            <span style={{textAlign: 'end', paddingRight: '1rem'}} className="num-amount">{'$' + memberTotalBySubscription.ThreeMonth * 20}</span>
                            <img src="/assets/img/pink_back.png" className="img-fluid card-img"></img>
                        </div>
                    </div>
                    <div className="inner-box-section">
                        <div className="number-charts position-relative">
                            <span className="info-item">6 Month subscribers</span>
                            <span style={{textAlign: 'end', paddingRight: '1rem'}} className="num-amount">{memberTotalBySubscription.SixMonth}</span>
                            <img src="/assets/img/black_back.png" className="img-fluid card-img"></img>
                        </div>
                        <div className="number-charts position-relative">
                            <span className="info-item">6 Month subscribers revenue</span>
                            <span style={{textAlign: 'end', paddingRight: '1rem'}} className="num-amount">{'$' + memberTotalBySubscription.SixMonth * 15}</span>
                            <img src="/assets/img/pink_back.png" className="img-fluid card-img"></img>
                        </div>
                    </div>
                 </div>
                 <div className="table-box-section">
                    <CustomTable 
                        {...defaultDatatableProps}
                        apiUrl= {`${BASE_URL}Data/ExecuteOld`}
                        tableColumns= {ADMIN_PAGES_COLUMNS.dashboard}
                        rowsPerPage= {DATATABLE_PROPS.ROW_PER_PAGE}
                        paginationStyles= {DATATABLE_PROPS.PAGINATEION_STYLE}
                        extraParams={{ RemoveModel: true, DbName: 'DbLetsConnect', QueryOrProcedureName: 'MEMBER_ListAdmin' }}
                    />
                 </div>
            </div>
        </div>
    );

}
export default DashboardComponent;