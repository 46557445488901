import React, { useEffect, useMemo, useState, useCallback } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import axios from "axios";
import './CustomTable.css';

interface PropsCustomTable {
  apiUrl?: string;
  apiHeaders?: any;
  tableColumns?: TableColumn<any>[];
  modelName?: string;
  customStyles?: object;
  paginationStyles?: any;
  loadingText?: string;
  loadingTextStyle?: any;
  enableSearch?: boolean;
  rowsPerPage?: number;
  additionalParams?: any;
  additionalMethod?: any;
  refreshTable?: boolean;
  handleRowClick?: any;
  handleActionButtonClick?: any;
  handelResponse?: any;
  extraParams?: any;
};

export const defaultStyle = {
  rows: {
    style: {
      minHeight: "32px",
      borderBottom: "0px !important",
    },
  },
  headRow: {
    style: {
      minHeight: "32px",
      backgroundColor: "#d7d8e1",
      borderTop: "0.5pt solid #ccc !important",
      borderBottom: "none !important",
    },
    denseStyle: {
      minHeight: "24px",
    },
  },
  headCells: {
    style: {
      fontSize: "9pt",
      border: "0.5pt solid #ccc !important",
      borderTop: "none !important",
      borderRight: "none !important",
      paddingLeft: '8px',
      paddingRight: '8px'
    },
  },
  cells: {
    style: {
      minHeight: "30px",
      fontSize: "9pt",
      border: "0.5pt solid #d7d8e1 !important",
      borderTop: "none !important",
      borderRight: "none !important",
      paddingLeft: '8px',
      paddingRight: '8px !important'
    },
    denseStyle: {
      minHeight: "30px",
    },
  }
};

export const paginationDefaultStyle = {
  pagination: {
    marginTop: "5px",
    justifyContent: "flex-end",
    display: "flex"
  },
  button: {
    height: '32px',
    color: 'rgba(0, 0, 0, 0.87)',
    border: '1px solid #ccc',
    backgroundColor: 'white'
  },
  active: {
    backgroundColor: 'rgb(215, 216, 225)',
    border: '1px solid rgb(215, 216, 225)'
  },
  inactive: {
    backgroundColor: '#FFFFFF'
  },
  hoverColor: '#d7d8e1'
};

export const defaultLoadingTextStyle = {
  fontSize: "20px",
  marginTop: "10px"
}



const CustomTable: React.FC<PropsCustomTable> = ({
  apiUrl,
  apiHeaders,
  tableColumns = [],
  modelName,
  enableSearch = true,
  refreshTable = false,
  rowsPerPage = 20,
  customStyles = defaultStyle,
  paginationStyles = paginationDefaultStyle,
  loadingTextStyle = defaultLoadingTextStyle,
  loadingText = 'Loading...',
  additionalParams = [],
  additionalMethod = () => { },
  handleActionButtonClick,
  handleRowClick,
  handelResponse,
  extraParams
}) => {

  const [loading, setLoading] = useState(false);
  const [filterParams, setFilterParams] = useState<any>([
    // TODO: fix params custom tabkle
    { Name: "OrderBy", Value: null },
    { Name: "OrderByDir", Value: null },
    { Name: "SearchString", Value: null },
    { Name: "Page", Value: 1 },
    { Name: "PageSize", Value: rowsPerPage }
  ]);
  const [data, setData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [showPagination, setShowPagination] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const buttonRef = useCallback((node: any) => {
    if (paginationStyles.hoverColor && node) {
      node.style.setProperty("--pagination-hover-color", paginationStyles.hoverColor);
    }
  }, []);

  const progressComponent = (
    <div style={loadingTextStyle}>{loadingText}</div>
  );

  function toPages(pages: any = []) {
    const results = [];
    for (let i = 1; i <= pages; i++) {
      results.push(i);
    }
    return results;
  }

  const handlePageChange = (page: any) => {
    filterParams[3].Value = page;
    setFilterParams(filterParams);
    getTableData();
  };

  const paginationComponent = ({ rowCount, currentPage }: any) => {
    const handleBackButtonClick = () => {
      handlePageChange(currentPage - 1);
      setCurrentPage(currentPage - 1);
    };

    const handleNextButtonClick = () => {
      handlePageChange(currentPage + 1);
      setCurrentPage(currentPage + 1);
    };

    const handlePageNumber = (e: any) => {
      handlePageChange(Number(e.target.value));
      setCurrentPage(Number(e.target.value));
    };

    const pages = Math.ceil(rowCount / rowsPerPage);
    const pageItems = toPages(pages);
    const nextDisabled = currentPage === pageItems.length;
    const previousDisabled = currentPage === 1;

    return (
      <nav>
        <ul className="jkc-custom-data-table-pagination" style={paginationStyles.pagination}>
          <li>
            <button
              ref={buttonRef}
              style={paginationStyles.button}
              className="next-prev-btn"
              onClick={handleBackButtonClick}
              disabled={previousDisabled}
              aria-disabled={previousDisabled}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M14 17a1 1 0 0 1-.707-.293l-4-4a1 1 0 0 1 0-1.414l4-4a1 1 0 1 1 1.414 1.414L11.414 12l3.293 3.293A1 1 0 0 1 14 17z" style={{ fill: '#1c1b1e' }} /></svg>
            </button>
          </li>
          {pageItems.map((page) => {
            return (
              <li key={page}>
                <button ref={buttonRef} disabled={page === currentPage} value={page} onClick={handlePageNumber} style={page === currentPage ? { ...paginationStyles.button, ...paginationStyles.active } : { ...paginationStyles.button, ...paginationStyles.inactive }} >
                  {page}
                </button>
              </li>
            );
          })}
          <li>
            <button
              ref={buttonRef}
              style={paginationStyles.button}
              className="next-prev-btn"
              onClick={handleNextButtonClick}
              disabled={nextDisabled}
              aria-disabled={nextDisabled}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="m14.707 12.707-4 4a1 1 0 0 1-1.414-1.414L12.586 12 9.293 8.707a1 1 0 1 1 1.414-1.414l4 4a1 1 0 0 1 0 1.414z" style={{ fill: '#1c1b1e' }} /></svg>
            </button>
          </li>
        </ul>
      </nav>
    );
  };

  const clearFilter = () => {
    setFilterText('');
    filterParams[2].Value = '';
    setFilterParams(filterParams);
    sessionStorage.removeItem('searchTxt');
    getTableData();
  };

  const headerComponent = useMemo(() => {
    return (
      enableSearch && (
        <SearchTextBox
          onFilter={async (e: { target: { value: any } }) => {
            sessionStorage.setItem('searchTxt', e.target.value);
            setFilterText(e.target.value);
            filterParams[2].Value = e.target.value;
            filterParams[3].Value = 1;
            setCurrentPage(1);
            setFilterParams(filterParams);
            getTableData();
          }}
          value={filterText ?? ''}
          clearFilter={clearFilter}
          filterText={filterText}
        />
      )
    );
  }, [filterText]);

  const handleSort = async (column: any, sortDirection: any) => {
    if (column.name) {
      setLoading(true);
      filterParams[0].Value = column.name
        ? column.name.replace(/\s/gm, "")
        : "";
      filterParams[1].Value = sortDirection;
      setFilterParams(filterParams);
      getTableData();
      setLoading(false);
    }
  };

  const buttonClick = (row: any, column: string) => {
    handleActionButtonClick(row, column);
  };

  const getTableData = async () => {
    let cpparams = (additionalParams.length == 0) ? filterParams : [...filterParams, ...additionalParams];
    let nParams = JSON.parse(JSON.stringify(cpparams));
    if (sessionStorage.getItem('searchTxt')) {
      setFilterText(sessionStorage.getItem('searchTxt'));
      nParams[2].Value = sessionStorage.getItem('searchTxt');
    }
    setLoading(true);
    let payload: any = {
      Model: modelName,
      RemoveModel: false,
      Params: nParams
    }
    if (extraParams) {
      Object.keys(extraParams).forEach((key: any) => {
        payload[key] = extraParams[key];
      });
      if (extraParams.RemoveModel) {
        delete payload.Model;
      }
    }
    delete payload.RemoveModel;
    const response = await axios.post(`${apiUrl}`, payload, { headers: apiHeaders });
    let result = response.data;
    if (!extraParams || !extraParams.RemoveModel) {
      result = response.data.data.ListInfo;
    }

    result.map((res: any) => {
      res["buttonClick"] = buttonClick;
      res["additionalMethod"] = additionalMethod;
    });
    
    setData(result);
    setTotalRows(result[0]?.RecordCount ?? rowsPerPage);
    setShowPagination(result[0]?.RecordCount > rowsPerPage);
    handelResponse && handelResponse(response);
    setLoading(false);
  };

  useEffect(() => {
    getTableData();
  }, []);

  useEffect(() => {
    refreshTable && getTableData();
  }, [refreshTable]);

  return (
    <React.Fragment>
      <div className="jkc-custom-data-table-header">
        {headerComponent}
      </div>
      <div className="jkc-custom-data-table">
        <DataTable
          responsive={true}
          customStyles={customStyles}
          columns={tableColumns}
          data={data}
          onSort={handleSort}
          onRowClicked={handleRowClick}
          persistTableHead
          progressPending={loading}
          progressComponent={progressComponent}
          pagination={showPagination}
          paginationServer
          paginationDefaultPage={currentPage}
          paginationPerPage={rowsPerPage}
          paginationTotalRows={totalRows}
          paginationComponent={paginationComponent}
        />
      </div>
    </React.Fragment>
  );
};

const SearchTextBox = ({ filterText, onFilter, clearFilter }: any) => (
  <React.Fragment>
    <label className="jkc-custom-data-table-search-input">
      <input
        value={filterText}
        style={{ fontSize: "14px" }}
        onChange={onFilter}
        type="text"
        placeholder="Search"
        name="name"
      />
      {filterText &&
        <svg onClick={clearFilter} className="jkc-custom-data-table-search-clear-icon" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
          width="15" height="15"
          viewBox="0 0 48 48">
          <path d="M 39.486328 6.9785156 A 1.50015 1.50015 0 0 0 38.439453 7.4394531 L 24 21.878906 L 9.5605469 7.4394531 A 1.50015 1.50015 0 0 0 8.484375 6.984375 A 1.50015 1.50015 0 0 0 7.4394531 9.5605469 L 21.878906 24 L 7.4394531 38.439453 A 1.50015 1.50015 0 1 0 9.5605469 40.560547 L 24 26.121094 L 38.439453 40.560547 A 1.50015 1.50015 0 1 0 40.560547 38.439453 L 26.121094 24 L 40.560547 9.5605469 A 1.50015 1.50015 0 0 0 39.486328 6.9785156 z"></path>
        </svg>}
    </label>
  </React.Fragment>
);

export default CustomTable;
