import axios from 'axios';

const mapToBase64 = async (res: any) => {
  const blob = await res.blob()
  let result_base64 = await new Promise((resolve) => {
    let reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onload = () => resolve(reader.result)
  })
  return result_base64
}

const mapRequestData = (params: any): any => {
  let dataToSave: any[] = []
  Object.keys(params).forEach((e) => {
    // if (e === 'Id') {
    //   return
    // }
    dataToSave.push({
      Name: e,
      Value: params[e],
    })
  })
  return dataToSave
}

export const List = async ({ params, apiUrl, token }: any) => {
  const body = mapRequestData(params.Params);
  try {
    const { data } = await axios.post(
      apiUrl,
      { ...params, Params: body  },
      {
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json', 'AppName': 'dbAdmin' },
      },
    )
    return {
      data: data.data.ListInfo,
      status: 'success',
      message: data.info,
    }
  } catch (error: any) {
    return {
      data: [],
      status: 'error',
      message: `Something went wrong!`,
    }
  }
}

export const Retrieve = async ({ params, apiUrl, token }: any) => {
  const body = mapRequestData(params.Params);
  try {
    const response = await axios.post<any>(
      apiUrl,
      { ...params, Params: body  },
      {
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json', 'AppName': 'dbAdmin' },
      },
    )
    return {
      data: response.data,
      status: 'success',
      message: response.data.info,
    }
  } catch (error: any) {
    return {
      data: [],
      status: 'error',
      message: `Error getting in retrieve!`,
    }
  }
}
export const RetrieveAd = async ({ params, apiUrl, token }: any) => {
  const body = mapRequestData(params.Params);
  console.log(params, body);
  
  try {
    const response = await axios.post<any>(
      apiUrl,
      { ...params, Params: body  },
      {
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json', 'AppName': 'dbAdmin' },
      },
    )
    return {
      data: response.data,
      status: 'success',
      message: response.data.info,
    }
  } catch (error: any) {
    return {
      data: [],
      status: 'error',
      message: `Error getting in retrieve!`,
    }
  }
}

export const Save = async ({ apiUrl, params, token }: any) => {
  const body = mapRequestData(params.Params);
  try {
    const { data } = await axios.post(
      apiUrl,
      { ...params, Params: body  },
      {
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json', 'AppName': 'dbAdmin' }
      },
    )
    return {
      data: data,
      status: 'success',
      error: data.error,
      message: data.info,
    }
  } catch (error: any) {
    return {
      data: null,
      status: 'error',
      error: error.response,
      message: `Error saving File!`
    }
  }
}

export const Delete = async ({ params, apiUrl, token }: any) => {
  const body = mapRequestData(params.Params);
  try {
    const { data } = await axios.post(
      apiUrl,
      { ...params, Params: body  },
      {
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json', 'AppName': 'dbAdmin' },
      },
    )
    return {
      data: data.data,
      status: 'success',
      message: data.info,
    }
  } catch (error: any) {
    return {
      data: [],
      status: 'error',
      message: `Something went wrong!`,
    }
  }
}

export const DownloadFile = async ({ apiUrl, token }: any) => {
  try {
    const res = await fetch(apiUrl, {
      headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json', 'AppName': 'dbAdmin' },
      method: 'GET',
    })
    if (!res.ok) {
      return {
        data: null,
        status: 'error',
        message: res.statusText,
      }
    }
    const resultBase64 = await mapToBase64(res)
    return {
      data: resultBase64,
      status: 'success',
      message: res.statusText,
    }
  } catch (error: any) {
    return {
      data: null,
      status: 'error',
      error: error.response,
      message: `Error saving File!`
    }
  }
}

export const FileUpload = async ({
  apiUrl,
  token,
  formData
}: any) => {
  try {
    const { data } = await axios.post(apiUrl, formData, {
      headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json', 'AppName': 'DbLetsConnect' },
    })
    return {
      data: data,
      status: 'success',
      error: data.error,
      message: data.info,
    }
  } catch (error: any) {
    console.log(error)
    return {
      data: null,
      status: 'error',
      error: error.response,
      message: `Error saving File!`
    }
  }
}

export const DeleteFiles = async ({ Id = 0, apiUrl, token }: any) => {
  try {
    // const { data } = await axios.delete(`${BASE_API}${SERVICES}${WIDGETS}/Files/Delete`, {
    const { data } = await axios.post(apiUrl,
      {
        Id: Id
      },
      {
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json', 'AppName': 'dbAdmin' }
      }
    );
    return {
      data: data.data,
      status: 'success',
      error: data.error,
      message: data.info,
    }
  } catch (error: any) {
    return {
      data: null,
      status: 'error',
      error: error.response,
      message: `Error saving File!`
    }
  }
}


export const ListFiles = async ({ MetasId = 0, apiUrl, token }: any) => {
  try {
    const { data } = await axios.post(
      // `${BASE_API}${SERVICES}${WIDGETS}/Files/List`,
      apiUrl,
      {
        MetasId,
      },
      {
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json', 'AppName': 'dbAdmin' },
      },
    )
    return {
      data: data.data,
      status: 'success',
      error: data.error,
      message: data.info,
    }
  } catch (error: any) {
    return {
      data: null,
      status: 'error',
      error: error.response,
      message: `Error in lists File!`
    }
  }
}