import React from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import "./App.scss";
import "./scss/style.scss";
import 'react-toastify/dist/ReactToastify.css';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import * as ChartGeo from "chartjs-chart-geo";
import AdminRoutes from './routes/AdminRoutes';
import AdminLayout from "./template/layout/admin";
import ClearData from "./template/pages/clear";
import AuthRoutes from './routes/AuthRoutes';
import AuthLayout from "./template/layout/auth";
import { ToastContainer } from 'react-toastify';

// MSAL imports
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";

type AppProps = {
  pca: IPublicClientApplication;
};

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  ChartGeo.ChoroplethController,
  ChartGeo.ProjectionScale,
  ChartGeo.ColorScale,
  ChartGeo.GeoFeature
);

function App({ pca }: AppProps) {
  return (
    <MsalProvider instance={pca}>
      <Routes>
        <Route path="/" element={<Outlet />} >
          {
            AuthRoutes.map((element:any) => {
              return (
                <Route key={element} path={element.path} element={<AuthLayout component={element.component} />} />
              );
            })
          }
        </Route>
        <Route path="/" element={<Outlet />} >
          {
            AdminRoutes.map((element:any) => {
              return (
                <Route key={element} path={element.path} element={<AdminLayout component={element.component} />} />
              );
            })
          }
        </Route>
        <Route path="*" element={
          <main style={{ padding: "1rem" }}>
            <p>There's nothing here!</p>
          </main>}
        />
        <Route path="/clearData" element={<ClearData/>} />
      </Routes>
      <ToastContainer />
    </MsalProvider>
  );
}

export default App;
