import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Modal, Button } from 'react-bootstrap';
import { JkcInput } from '@softsmart/jkc-input';
import JkCropper from '../../components/cropper/cropper';
import { Retrieve, Save, FileUpload } from '../../utils/ApiService';
import LoadingOverlay from 'react-loading-overlay-ts';
import '../../utils/pages.css';
import { toast } from 'react-toastify';
import { BUTTON_STYLE } from '../../shared/services/Constants';
import axios from 'axios';
import { BASE_URL } from '../../shared/services/RouteConst';

const MemberDetail = ({
    BASE_API,
    UPLOAD_DOWNLOAD_API,
    buttonStyle,
    pageTitle = 'Members Detail',
    fieldTypes = 'Normal', // Normal, Grid
    pageTitleStyle,
    headerStyle = { display: 'flex', flexDirection: "row", justifyContent: 'space-between' },
    tabStyle = {},
    customStyle = {},
    modelStyle = {},
}: any) => {
    const { Id } = useParams();
    const inputRef = useRef<any>(null);
    const [nextId, setNextId] = useState(false);
    const [prevId, setPrevId] = useState(false);
    const [isActive, setActive] = useState(false);
    const [imageLoader, setImageLoader] = useState(false);
    const [videoLoader, setVideoLoader] = useState(false);
    const [reloadVideo, setReloadVideo] = useState(true);
    const [activeKey, setActiveKey] = useState('typeInfo');
    const [searchTxt, setSearchTxt] = useState('');
    const [membersData, setMembersData] = useState([]);
    const [userLocation, setUserLocation] = useState('');
    const [profilePicture, setProfilePicture] = useState('');
    const [profileVideo, setProfileVideo] = useState('');
    const [isShow, setShow] = React.useState(false);
    const [payload, setPayLoad] = React.useState({});
    const [typeId, setTypeId] = React.useState(0);
    const [userData, setUserData] = useState({
        MemberId: Id || '0',
        FirstName: "",
        LastName: "",
        UserName: "",
        City: "",
        SexMaleFemale: "",
        Phone: "",
        Email: "",
        ZipCode: "",
        AccountNumber: "",
        DateOfBirth: "",
        SubscriptionType: "",
        SubscriptionAmount: "",
        SubscriptionStartDate: "",
        SubscriptionEndDate: "",
        // IsSubscriptionActive: ""
    });
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const navigate = useNavigate()
    const [blockData, setBlockData] = useState([]);
    const [callData, setCallData] = useState([]);
    const [chatsData, setChatsData] = useState([]);
    const [contatsData, setContatsData] = useState([]);
    const [favoritesData, setFavoritesData] = useState([]);
    const [acceptedData, setAcceptedData] = useState([]);
    const [pendingData, setPendingData] = useState([]);
    const [notificationData, setNotificationData] = useState([]);

    const [isblockData, setIsBlockData] = useState(false);
    const [iscallData, setIsCallData] = useState(false);
    const [ischatsData, setIsChatsData] = useState(false);
    const [iscontatsData, setIsContatsData] = useState(false);
    const [isfavoritesData, setIsFavoritesData] = useState(false);
    const [isacceptedData, setIsAcceptedData] = useState(false);
    const [isNotificationData, setIsNotificationData] = useState(false);
    const authUser = (localStorage.getItem('User')) ? JSON.parse(localStorage.getItem('User')) : null;


    const subscriptions = [{
        value: "1",
        label: "1 Months",
    }, {
        value: "3",
        label: "3 Months",
    }, {
        value: "6",
        label: "6 Months",
    }];

    const tabRef = useCallback((node: any) => {
        if (tabStyle.borderColor && node) {
            node.style.setProperty("--nav-tab-border-color", "#f5f5f5");
        }
        if (tabStyle.activeColor && node) {
            node.style.setProperty("--nav-tab-active-text-color", tabStyle.borderColor);
        }
        if (tabStyle.inactiveColor && node) {
            node.style.setProperty("--nav-tab-inactive-text-color", tabStyle.inactiveColor);
        }
        if (tabStyle.fontSize && node) {
            node.style.setProperty("--nav-tab-font-size", tabStyle.fontSize);
        }
        if (tabStyle.padding && node) {
            node.style.setProperty("--nav-tab-padding", tabStyle.padding);
        }
    }, []);

    let inputStyle = {
        borderColor: "#DEE2E6",
        borderRadius: '4px',
        padding: "0px 7px 0px 7px",
        height: '40px',
        fontSize: "14px",
        background: 'transparent',
        color: "#747474"
    }

    let labelStyle = {
        color: "#212529",
        paddingLeft: '7px',
        fontSize: "13px",
        fontWeight: "500"
    }

    if (customStyle && customStyle.inputStyle) {
        inputStyle = customStyle.inputStyle;
    }

    if (customStyle && customStyle.labelStyle) {
        labelStyle = customStyle.labelStyle;
    }

    const [settings, setSettings] = useState<any>({
        label: "",
        value: "",
        className: "",
        placeholder: "",
        mask: "(999)-999-9999",
        maskChar: "#",
        maskPlaceholder: "#",
        alwaysShowMask: true,
        iconShowHideToggle: false,
        isNormalLabel: true,
        hideLabel: false,
        inputStyle: inputStyle,
        labelStyle: labelStyle,
        fieldTypes: fieldTypes,
        inline: true,
        json: [
            {
                name: "Male",
                value: "Male",
            },
            {
                name: "Female",
                value: "Female",
            }
        ]
    });

    const [imageState, setImageState] = useState({ file: null })
    const handleFile = (e) => {
        let file = e.target.files[0]
        setImageState({ file: file })
    }
    const handleUpload = (e) => {
        console.log(imageState, 'the state');
        uploadFile2(imageState)
        viewData(Id)
    }

    const viewData = React.useCallback(async (Id) => {
        if (!Id) {
            return;
        }
        setReloadVideo(false);
        setActive(true);
        const { error: apiError, data: apiData }: any = await Retrieve({
            params: {
                DbName: "DbLetsConnect",
                QueryOrProcedureName: "MEMBER_Retrieve",
                Params: {
                    MemberId: Id
                }
            },
            apiUrl: `${BASE_URL}Data/ExecuteOld`,
            token: localStorage.getItem('AdminToken')
        })
        if (apiData && apiData.length && apiData[0].FilesId_Profilepicture > 0) {
            await setProfilePicture('https://letsconnect.azureedge.net/members/' + apiData[0].ProfilePicture)
        } else {
            setProfilePicture('');
        }
        if (apiData && apiData.length) {
            setUserData({
                MemberId: apiData[0].MemberId || "",
                FirstName: apiData[0].FirstName || "",
                LastName: apiData[0].LastName || "",
                UserName: apiData[0].UserName || "",
                City: apiData[0].City || "",
                SexMaleFemale: apiData[0].SexMaleFemale || "",
                Phone: apiData[0].Phone || "",
                Email: apiData[0].Email || "",
                ZipCode: apiData[0].ZipCode || "",
                AccountNumber: apiData[0].AccountNumber || "",
                DateOfBirth: apiData[0].DateOfBirth || "",
                SubscriptionType: apiData[0].SubscriptionType || "",
                SubscriptionAmount: apiData[0].SubscriptionAmount || "",
                SubscriptionStartDate: apiData[0].SubscriptionStartDate || "",
                SubscriptionEndDate: apiData[0].SubscriptionEndDate || "",
                // IsSubscriptionActive: (apiData[0].IsSubscriptionActive) ? apiData[0].IsSubscriptionActive + "": "0"
            });
            if (apiData[0].LocationLatitude && apiData[0].LocationLongitude) {
                axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${apiData[0].LocationLatitude},${apiData[0].LocationLongitude}&key=AIzaSyDSZ09ZdKQqyK7F75-iOGsYuLFC20sogcY`).then((resp: any) => {
                    if (resp && resp.data && resp.data.results) {
                        let localAddress = "";
                        for (let i = 0; i < resp.data.results.length; i++) {
                            if (localAddress === "") {
                                if (resp.data.results[i].types.indexOf('street_address') !== -1) {
                                    console.log(resp.data.results[i])
                                    localAddress = resp.data.results[i].formatted_address;
                                } else if (resp.data.results[i].types.indexOf('administrative_area_level_2') !== -1) {
                                    localAddress = resp.data.results[i].formatted_address;
                                }
                            }
                        }
                        setUserLocation(localAddress);
                    }
                });
            }
            setNextId(apiData[0].NextId)
            setPrevId(apiData[0].PrevId)

            if (apiData[0].FilesId_Profilevideo > 0) {
                setProfileVideo('https://letsconnect.azureedge.net/members/' + apiData[0].ProfileVideo)
            } else {
                setProfileVideo('');
            }
        }
        setActive(false);
        setReloadVideo(true);
        getBlockedUser();
        getCalls();
        getChats();
        getContacts();
        getFavorites();
        getConnections();
        getNotification();
    }, []);

    const nextOrPrevProfile = async (type: string) => {
        let mId = null;
        if (type === 'N') {
            mId = nextId
        }
        if (type === 'P') {
            mId = prevId
        }
        if (!mId) {
            return
        }

        navigate(`/Admin/Member/Detail/${mId}`);
    }

    const viewProfile = async (r: any) => {
        searchMember("")
        setMembersData([]);
        navigate(`/Admin/Member/Detail/${r.MemberId}`);
    }

    const getBlockedUser = async () => {
        const { data: bData }: any = await Retrieve({
            params: {
                DbName: "DbLetsConnect",
                QueryOrProcedureName: "MEMBER_BLOCKED_List",
                Params: {
                    MemberId: userData.MemberId
                }
            },
            apiUrl: `${BASE_URL}Data/ExecuteOld`,
            token: localStorage.getItem('AdminToken')
        })
        setIsBlockData(true);
        if (bData) {
            setBlockData(bData);
        }
    }

    const getCalls = async () => {
        const { data: bData }: any = await Retrieve({
            params: {
                DbName: "DbLetsConnect",
                QueryOrProcedureName: "MEMBER_CALLS_List",
                Params: {
                    MemberId: userData.MemberId
                }
            },
            apiUrl: `${BASE_URL}Data/ExecuteOld`,
            token: localStorage.getItem('AdminToken')
        })
        setIsCallData(true);
        if (bData) {
            setCallData(bData);
        }
    }

    const getFavorites = async () => {
        const { data: bData }: any = await Retrieve({
            params: {
                DbName: "DbLetsConnect",
                QueryOrProcedureName: "MEMBER_FAVORITES_List",
                Params: {
                    MemberId: userData.MemberId
                }
            },
            apiUrl: `${BASE_URL}Data/ExecuteOld`,
            token: localStorage.getItem('AdminToken')
        })
        setIsFavoritesData(true);
        if (bData) {
            setFavoritesData(bData);
        }
    }

    const getContacts = async () => {
        const { data: bData }: any = await Retrieve({
            params: {
                DbName: "DbLetsConnect",
                QueryOrProcedureName: "MEMBER_CONTACTS_List",
                Params: {
                    MemberId: userData.MemberId
                }
            },
            apiUrl: `${BASE_URL}Data/ExecuteOld`,
            token: localStorage.getItem('AdminToken')
        })
        setIsContatsData(true);
        if (bData) {
            setContatsData(bData);
        }
    }

    const getChats = async () => {
        const { data: bData }: any = await Retrieve({
            params: {
                DbName: "DbLetsConnect",
                QueryOrProcedureName: "MEMBER_CHAT_ListGrouped",
                Params: {
                    MemberId: userData.MemberId
                }
            },
            apiUrl: `${BASE_URL}Data/ExecuteOld`,
            token: localStorage.getItem('AdminToken')
        })
        setIsChatsData(true);
        if (bData) {
            setChatsData(bData);
        }
    }

    const getNotification = async () => {
        const { data: bData }: any = await Retrieve({
            params: {
                DbName: "DbLetsConnect",
                QueryOrProcedureName: "MEMBER_NOTIFICATION_Retrieve_By_MemberId",
                Params: {
                    MemberId: userData.MemberId
                }
            },
            apiUrl: `${BASE_URL}Data/ExecuteOld`,
            token: localStorage.getItem('AdminToken')
        })
        setIsNotificationData(true);
        if (bData) {
            bData.map((r: any) => {
                if (r.NotificationJson) {
                    r.fromName = "";
                    let dArray = r.NotificationJson.split(', ');
                    let objs = dArray.find(r => r.indexOf('senderName:') != -1);
                    if (objs) {
                        let nameArray = objs.split('senderName: ');
                        if (nameArray.length && nameArray.length > 1) {
                            r.fromName = nameArray[1];
                        }
                    }
                }
            })

            setNotificationData(bData);
        }
    }

    const getConnections = async () => {
        const { data: bData }: any = await Retrieve({
            params: {
                DbName: "DbLetsConnect",
                QueryOrProcedureName: "MEMBER_INVITATION_ListPending",
                Params: { MemberId: parseInt(userData.MemberId) }
            },
            apiUrl: `${BASE_URL}Data/ExecuteOld`,
            token: localStorage.getItem('AdminToken')
        })
        setIsAcceptedData(true);
        if (bData.data) {
            setAcceptedData(bData.data.Connections);
            setPendingData(bData.data.Pending);
        }
    }

    const deleteBlocked = async (item) => {
        const { data: bData }: any = await Retrieve({
            params: {
                DbName: "DbLetsConnect",
                QueryOrProcedureName: "MEMBER_BLOCKED_Delete",
                Params: {
                    MemberBlockedId: item.MemberBlockedId,
                    MemberId_Primary: item.MemberId_Primary,
                    MemberId_Blocked: item.MemberId_Blocked,
                }
            },
            apiUrl: `${BASE_URL}Data/ExecuteOld`,
            token: localStorage.getItem('AdminToken')
        })
        if (bData) {
            getBlockedUser();
        }
    }

    const deleteCall = async (item) => {
        const { data: bData }: any = await Retrieve({
            params: {
                DbName: "DbLetsConnect",
                QueryOrProcedureName: "MEMBER_CALLS_Delete",
                Params: {
                    MemberCallsId: item.MemberCallsId,
                    ChangedBy: (authUser) ? authUser.Auth.User.FirstName : "",
                }
            },
            apiUrl: `${BASE_URL}Data/ExecuteOld`,
            token: localStorage.getItem('AdminToken')
        })
        if (bData) {
            getCalls();
        }
    }

    const deleteChat = async (item) => {
        const { data: bData }: any = await Retrieve({
            params: {
                DbName: "DbLetsConnect",
                QueryOrProcedureName: "MEMBER_CHAT_Delete",
                Params: {
                    MemberChatId: item.MemberChatId,
                    ChangedBy: (authUser) ? authUser.Auth.User.FirstName : "",
                }
            },
            apiUrl: `${BASE_URL}Data/ExecuteOld`,
            token: localStorage.getItem('AdminToken')
        })
        if (bData) {
            getChats();
        }
    }

    const deleteFavorites = async (item) => {
        const { data: bData }: any = await Retrieve({
            params: {
                DbName: "DbLetsConnect",
                QueryOrProcedureName: "MEMBER_FAVORITES_Delete",
                Params: {
                    MemberFavoritesId: item.MemberFavoritesId,
                    MemberId_Primary: item.MemberId_Primary,
                    MemberId_Favorite: item.MemberId_Favorite,
                }
            },
            apiUrl: `${BASE_URL}Data/ExecuteOld`,
            token: localStorage.getItem('AdminToken')
        })
        if (bData) {
            getFavorites();
        }
    }

    const deleteMember = async () => {
        const { data: bData }: any = await Retrieve({
            params: {
                "DbName": "DbLetsConnect",
                "QueryOrProcedureName": "MEMBER_DeleteFull",
                Params: {
                    MemberId: userData.MemberId
                }
            },
            apiUrl: `${BASE_URL}Data/ExecuteOld`,
            token: localStorage.getItem('AdminToken')
        })
        if (bData) {
            let mId = prevId ? prevId : nextId;
            if (!mId) {
                return
            }
            toast.success("Profile Delete");
            navigate(`/Admin/Member/Detail/${mId}`);
        }
    };

    const blockMember = async () => {
        const { data: bData }: any = await Retrieve({
            params: {
                "DbName": "DbLetsConnect",
                "QueryOrProcedureName": "MEMBER_BLOCK",
                Params: {
                    MemberId: userData.MemberId
                }
            },
            apiUrl: `${BASE_URL}Data/ExecuteOld`,
            token: localStorage.getItem('AdminToken')
        })
        if (bData) {
            let mId = prevId ? prevId : nextId;
            if (!mId) {
                return
            }
            toast.success("Profile Delete");
            navigate(`/Admin/Member/Detail/${mId}`);
        }
    };

    const deleteContact = async (item) => {
        const { data: bData }: any = await Retrieve({
            params: {
                DbName: "DbLetsConnect",
                QueryOrProcedureName: "MEMBER_CONTACTS_Delete",
                Params: {
                    MemberContactsId: item.MemberContactsId,
                    MemberId_Primary: item.MemberId_Primary,
                    MemberId_Contact: item.MemberId_Contact,
                }
            },
            apiUrl: `${BASE_URL}Data/ExecuteOld`,
            token: localStorage.getItem('AdminToken')
        })
        if (bData) {
            getContacts();
        }
    }

    const deleteInvitation = async (item) => {
        const { data: bData }: any = await Retrieve({
            params: {
                DbName: "DbLetsConnect",
                QueryOrProcedureName: "MEMBER_INVITATION_Delete",
                Params: {
                    MemberInvitationId: item.MemberInvitationId,
                    ChangedBy: (authUser) ? authUser.Auth.User.FirstName : "",
                }
            },
            apiUrl: `${BASE_URL}Data/ExecuteOld`,
            token: localStorage.getItem('AdminToken')
        })
        if (bData) {
            getContacts();
        }
    }

    const uploadFile = async (file: any, fileCroppedBase64: any) => {
        setImageLoader(true);
        let fileName = userData.MemberId + "-" + (Math.random() + 1).toString(36).substring(7) + ".jpg";
        const formData = new FormData()
        formData.append('Id', userData.MemberId || '')
        formData.append('File', file, fileName);
        formData.append('FileType', 'profile');
        formData.append('Description', 'application/jpeg');

        const { data, error } = await FileUpload({
            apiUrl: `https://api.letsconnect123.com/Services/v2/LetsConnect/Files/Upload`,
            token: localStorage.getItem('AdminToken'),
            formData: formData
        })
        if (data && data.FileInfo) {
            setProfilePicture(fileCroppedBase64);
        } else {
            toast.error("Error saving data!");
        }
        setImageLoader(false);
    }

    const uploadFile2 = async ({ file }) => {
        setImageLoader(true);
        let fileName = userData.MemberId + "-" + (Math.random() + 1).toString(36).substring(7) + ".jpg";
        const formData = new FormData()
        formData.append('Id', userData.MemberId || '')
        formData.append('File', file, fileName);
        formData.append('FileType', 'profile');
        formData.append('Description', 'application/jpg');

        const { data, error } = await FileUpload({
            apiUrl: `https://api.letsconnect123.com/Services/v2/LetsConnect/Files/Upload`,
            token: localStorage.getItem('AdminToken'),
            formData: formData
        })
        setImageLoader(false);
    }

    useEffect(() => {
        viewData(Id)
    }, [Id])

    const onChangeEvent = (event: any) => {
        const name = event.target.name;
        const value = event.target.value;
        setUserData({ ...userData, [name]: value })
    }

    const onChangeDate = (name: any, value: any) => {
        setUserData({ ...userData, [name]: value })
    }

    const onChangeApps = (event: any) => {
        let v = null;
        if (event) {
            v = event.value;
        }
        setUserData({ ...userData, SubscriptionType: v });
    }

    const saveData = async () => {
        setActive(true);
        const { data: response, message: saveMessage } = await Save({
            params: {
                DbName: "DbLetsConnect",
                QueryOrProcedureName: "MEMBER_Save",
                Params: userData,
            },
            apiUrl: `${BASE_URL}Data/ExecuteOld`,
            token: localStorage.getItem('AdminToken')
        });
        let saveData = response;
        if (saveData && saveData.length) {
            setUserData({ ...userData, MemberId: saveData[0].MemberId });
            console.log('userData', userData);

        }

        if (saveData[0].MemberId) {
            navigate('/Admin/Member/Detail/' + saveData[0].MemberId)
        }
        setActive(false);
    }

    const resetData = async () => {
        setActive(true);
        await Save({
            params: {
                DbName: "DbLetsConnect",
                QueryOrProcedureName: "MEMBER_Reset",
                Params: { MemberId: userData.MemberId },
            },
            apiUrl: `${BASE_URL}Data/ExecuteOld`,
            token: localStorage.getItem('AdminToken')
        });
        setActive(false);
        window.location.reload();
    }

    const updatePasswordData = async () => {
        if (password !== confirmPassword) {
            toast.error("Passwords do not match.")
            return false;
        }
        setActive(true);
        await Save({
            params: {
                DbName: "DbLetsConnect",
                QueryOrProcedureName: "MEMBER_SavePasswordNew",
                Params: {
                    MemberId: userData.MemberId,
                    Password: password,
                    ChangedBy: (authUser) ? authUser.Auth.User.FirstName : ""
                },
            },
            apiUrl: `${BASE_URL}Data/ExecuteOld`,
            token: localStorage.getItem('AdminToken')
        });
        setPassword("");
        setConfirmPassword("");
        setActive(false);
    }

    const deleteData = async (obj: any, type: any) => {
        setShow(true);
        setPayLoad(obj);
        setTypeId(type);
    }

    const handleDelete = async () => {
        if (typeId === 1) {
            deleteBlocked(payload);
        }
        if (typeId === 2) {
            deleteCall(payload);
        }
        if (typeId === 3) {
            deleteChat(payload);
        }
        if (typeId === 4) {
            deleteContact(payload);
        }
        if (typeId === 5) {
            deleteFavorites(payload);
        }
        if (typeId === 6) {
            deleteMember();
        }
        if (typeId === 7) {
            blockMember();
        }
        setShow(false);
        setTypeId(0);
    }

    const triggerInvokedFromParent = () => {
        inputRef?.current?.click();
    }

    const onChangeVideo = async (e: any) => {
        setReloadVideo(false);
        setVideoLoader(true);
        let file = e.target.files[0];
        let fileName = userData.MemberId + "-" + file.name;
        let blobURL = URL.createObjectURL(file);
        const formData = new FormData()
        formData.append('Id', userData.MemberId || '')
        formData.append('File', file, fileName)
        formData.append('FileType', 'video');
        formData.append('Description', 'application/mp4');

        const { data, error } = await FileUpload({
            apiUrl: `https://api.letsconnect123.com/Services/v2/LetsConnect/Files/Upload`,
            token: localStorage.getItem('AdminToken'),
            formData: formData
        })
        if (data && data.FileInfo) {
            setProfileVideo(blobURL);
            setTimeout(() => {
                setReloadVideo(true);
            }, 300)
        } else {
            toast.error("Error while uploading video");
            setTimeout(() => {
                setReloadVideo(true);
            }, 300)
        }
        setVideoLoader(false);
    };

    const searchMember = async (val: any) => {
        setSearchTxt(val);
        if (val && val.length > 2) {
            const { data: bData }: any = await Retrieve({
                params: {
                    DbName: "DbLetsConnect",
                    QueryOrProcedureName: "MEMBER_ListAdmin",
                    Params: {
                        OrderBy: "FirstName",
                        OrderByDir: "ASC",
                        SearchString: val,
                        Page: 1,
                        PageSize: 10
                    }
                },
                apiUrl: `${BASE_URL}Data/ExecuteOld`,
                token: localStorage.getItem('AdminToken')
            })
            if (bData && bData.length) {
                setMembersData(bData);
            } else {
                setMembersData([]);
            }
        } else {
            setMembersData([]);
        }
    }



    useEffect(() => {
    }, [])


    return (
        <LoadingOverlay
            active={isActive}
            spinner
            text='Loading your content...'>
            <div className='p-3'>
                <div style={headerStyle}>
                    <div>
                        <h6 style={pageTitleStyle} className='mb-0'>{pageTitle}</h6>
                    </div>
                    <div className='position-relative'>
                        <input type="text" className='form-control' style={{ minWidth: 300, borderRadius: 0 }} value={searchTxt} placeholder="Search Member" onChange={(e) => searchMember(e.target.value)} />
                        {(searchTxt && searchTxt.length > 2) ?
                            <div className='member-list'>
                                {membersData.length ?
                                    membersData.map((r, k) => {
                                        return <div onClick={() => viewProfile(r)} key={k} className='person'>{r.FirstName} {r.LastName}</div>
                                    }) : null}
                            </div> : null}
                    </div>
                    <div>
                        <button onClick={resetData} style={buttonStyle} className='btn-theme-default me-2'>Reset</button>
                        <button onClick={saveData} style={buttonStyle} className='btn-theme-default me-2'>Save</button>
                        <button style={buttonStyle} onClick={() => deleteData({}, 6)} className='btn-theme-default me-2'>Delete</button>
                        <button style={buttonStyle} onClick={() => deleteData({}, 7)} className='btn-theme-default me-2'>Block</button>
                        <button onClick={() => nextOrPrevProfile('P')} style={buttonStyle} className='btn-theme-default me-2'>Previous Profile</button>
                        <button onClick={() => nextOrPrevProfile('N')} style={buttonStyle} className='btn-theme-default me-2'>Next Profile</button>
                        <Link to="/Admin/Member/Search" style={buttonStyle} className='btn-theme-default me-2'>Back</Link>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md-8 jkc-admin-detail-pages" ref={tabRef}>
                        <Tabs
                            defaultActiveKey="profile"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                            activeKey={activeKey}
                            onSelect={(k: any) => setActiveKey(k)}
                        >
                            <Tab eventKey="typeInfo" title="Detail">
                                <div className='sec-title text-center mb-3'>
                                    <div className='float-start'>Member Id : {userData.MemberId}</div>
                                    User Details
                                </div>
                                <div className="row">
                                    <div className={(settings.fieldTypes == 'Grid') ? "col-12" : "col-6"} style={{ marginTop: (settings.fieldTypes == 'Grid') ? "0px" : "10px" }}>
                                        <JkcInput type={'text'} name={"FirstName"} settings={{ ...settings, value: userData.FirstName, label: "First Name" }} onChangeEvent={onChangeEvent}></JkcInput>
                                    </div>
                                    <div className={(settings.fieldTypes == 'Grid') ? "col-12" : "col-6"} style={{ marginTop: (settings.fieldTypes == 'Grid') ? "0px" : "10px" }}>
                                        <JkcInput type={'text'} name={"LastName"} settings={{ ...settings, value: userData.LastName, label: "Last Name" }} onChangeEvent={onChangeEvent}></JkcInput>
                                    </div>
                                    <div className={(settings.fieldTypes == 'Grid') ? "col-12" : "col-6"} style={{ marginTop: (settings.fieldTypes == 'Grid') ? "0px" : "10px" }}>
                                        <JkcInput type={'text'} name={"UserName"} settings={{ ...settings, value: userData.UserName, label: "Username" }} onChangeEvent={onChangeEvent}></JkcInput>
                                    </div>
                                    <div className={(settings.fieldTypes == 'Grid') ? "col-12" : "col-6"} style={{ marginTop: (settings.fieldTypes == 'Grid') ? "0px" : "10px" }}>
                                        <JkcInput type={'text'} name={"City"} settings={{ ...settings, value: userData.City, label: "City" }} onChangeEvent={onChangeEvent}></JkcInput>
                                    </div>
                                    <div className={(settings.fieldTypes == 'Grid') ? "col-12" : "col-6"} style={{ marginTop: (settings.fieldTypes == 'Grid') ? "0px" : "10px" }}>
                                        <JkcInput type={'text'} name={"ZipCode"} settings={{ ...settings, value: userData.ZipCode, label: "ZipCode" }} onChangeEvent={onChangeEvent}></JkcInput>
                                    </div>
                                    <div className={(settings.fieldTypes == 'Grid') ? "col-12" : "col-6"} style={{ marginTop: (settings.fieldTypes == 'Grid') ? "0px" : "10px" }}>
                                        <JkcInput type={'text'} name={"userLocation"} settings={{ ...settings, readOnly: true, value: userLocation, label: "Location" }}></JkcInput>
                                    </div>
                                    {/* <div className={(settings.fieldTypes == 'Grid') ? "col-12" : "col-6"} style={{ marginTop: (settings.fieldTypes == 'Grid') ? "0px" : "10px" }}>
                                        <JkcInput type={'text'} name={"AccountNumber"} settings={{ ...settings, value: userData.AccountNumber, label: "Account Number" }} onChangeEvent={onChangeEvent}></JkcInput>
                                    </div> */}
                                    <div className={(settings.fieldTypes == 'Grid') ? "col-12" : "col-6"} style={{ marginTop: (settings.fieldTypes == 'Grid') ? "0px" : "10px" }}>
                                        <JkcInput type={'text'} name={"Email"} settings={{ ...settings, value: userData.Email, label: "Email" }} onChangeEvent={onChangeEvent}></JkcInput>
                                    </div>
                                    <div className={(settings.fieldTypes == 'Grid') ? "col-12" : "col-6"} style={{ marginTop: (settings.fieldTypes == 'Grid') ? "0px" : "10px" }}>
                                        <JkcInput type={'text'} name={"Phone"} settings={{ ...settings, value: userData.Phone, label: "Phone" }} onChangeEvent={onChangeEvent}></JkcInput>
                                    </div>
                                    <div className={(settings.fieldTypes == 'Grid') ? "col-12" : "col-6"} style={{ marginTop: (settings.fieldTypes == 'Grid') ? "0px" : "10px" }}>
                                        <JkcInput type={'date'} name={"DateOfBirth"} settings={{ ...settings, value: userData.DateOfBirth ? new Date(userData.DateOfBirth) : null, label: "Date Of Birth" }} onChangeEvent={(e: any) => onChangeDate('DateOfBirth', e)}></JkcInput>
                                    </div>
                                    <div className={(settings.fieldTypes == 'Grid') ? "col-12" : "col-6"} style={{ marginTop: (settings.fieldTypes == 'Grid') ? "0px" : "10px" }}>
                                        <JkcInput type={'radio'} name={"SexMaleFemale"} settings={{ ...settings, value: userData.SexMaleFemale, label: "Sex", inputStyle: { ...inputStyle, border: 'none' } }} onChangeEvent={onChangeEvent}></JkcInput>
                                    </div>
                                </div>
                                <div className='sec-title text-center my-3'>Subscription</div>
                                <div className="row">
                                    <div className={(settings.fieldTypes == 'Grid') ? "col-12" : "col-6"} style={{ marginTop: (settings.fieldTypes == 'Grid') ? "0px" : "10px" }}>
                                        {/* <JkcInput type={'text'} name={"SubscriptionType"} settings={{ ...settings, value: userData.SubscriptionType, label: "Subscription Type" }} onChangeEvent={onChangeEvent}></JkcInput> */}
                                        <JkcInput type={'select'} name={"SubscriptionType"} settings={{ ...settings, json: subscriptions, value: (userData.SubscriptionType) ? subscriptions.find(e => e.value == userData.SubscriptionType) : null, label: "Subscription" }} onChangeEvent={onChangeApps}></JkcInput>
                                    </div>
                                    <div className={(settings.fieldTypes == 'Grid') ? "col-12" : "col-6"} style={{ marginTop: (settings.fieldTypes == 'Grid') ? "0px" : "10px" }}>
                                        <JkcInput type={'text'} name={"SubscriptionAmount"} settings={{ ...settings, value: userData.SubscriptionAmount, label: "Subscription Amount" }} onChangeEvent={onChangeEvent}></JkcInput>
                                    </div>
                                    <div className={(settings.fieldTypes == 'Grid') ? "col-12" : "col-6"} style={{ marginTop: (settings.fieldTypes == 'Grid') ? "0px" : "10px" }}>
                                        <JkcInput type={'date'} name={"SubscriptionStartDate"} settings={{ ...settings, value: userData.SubscriptionStartDate ? new Date(userData.SubscriptionStartDate) : null, label: "Subscription Start Date" }} onChangeEvent={(e: any) => onChangeDate('SubscriptionStartDate', e)}></JkcInput>
                                    </div>
                                    <div className={(settings.fieldTypes == 'Grid') ? "col-12" : "col-6"} style={{ marginTop: (settings.fieldTypes == 'Grid') ? "0px" : "10px" }}>
                                        <JkcInput type={'date'} name={"SubscriptionEndDate"} settings={{ ...settings, value: userData.SubscriptionEndDate ? new Date(userData.SubscriptionEndDate) : null, label: "Subscription End Date" }} onChangeEvent={(e: any) => onChangeDate('SubscriptionEndDate', e)}></JkcInput>
                                    </div>
                                    {/* <div className={(settings.fieldTypes == 'Grid') ? "col-12": "col-6"} style={{marginTop: (settings.fieldTypes == 'Grid') ? "0px": "10px"}}>
                                        <JkcInput type={'radio'} name={"IsSubscriptionActive"} settings={{ ...settings, json: [{name: "Yes",value: "1"}, {name: "No",value: "0"}], value: userData.IsSubscriptionActive, label: "Is Subscription Active" }} onChangeEvent={onChangeEvent}></JkcInput>
                                    </div> */}
                                </div>
                            </Tab>
                            <Tab eventKey="keyBlock" title="Block">
                                <>
                                    <div className='sec-title text-center mb-3'>Block</div>
                                    <div className="header-table">
                                        <div className="row">
                                            <div className="col-1">
                                            </div>
                                            <div className="col-3">
                                                Name
                                            </div>
                                            <div className="col-6">
                                                Block
                                            </div>
                                            <div className="col-2 text-center">
                                                Action
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        blockData.map((user: any, k: any) => {
                                            return <div key={k} className='row-table'>
                                                <div className="row align-items-center">
                                                    <div className="col-1 text-center">
                                                        <img className='img-pink-border' width={32} src={'https://letsconnect.azureedge.net/members/' + user.MemberId_Blocked_ProfilePicture} />
                                                    </div>
                                                    <div className="col-3 cbg">
                                                        {user.FullName}
                                                    </div>
                                                    <div className="col-6">
                                                        <img width={18} src="/assets/img/block.png" />
                                                    </div>
                                                    <div className="col-2 text-center">
                                                        <button style={buttonStyle} onClick={() => deleteData(user, 1)} className='btn-theme-default me-2'>Delete</button>
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    }
                                    {isblockData && !blockData.length ? <div className="text-center mt-3">There are no records</div> : null}
                                </>
                            </Tab>
                            <Tab eventKey="KeyCalls" title="Calls">
                                <>
                                    <div className='sec-title text-center mb-3'>Calls</div>
                                    <div className="header-table">
                                        <div className="row">
                                            <div className="col-1">
                                            </div>
                                            <div className="col-3">
                                                Name
                                            </div>
                                            <div className="col-6">
                                                Call
                                            </div>
                                            <div className="col-2 text-center">
                                                Action
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        callData.map((user: any, k: any) => {
                                            return <div key={k} className='row-table'>
                                                <div className="row align-items-center">
                                                    <div className="col-1 text-center">
                                                        <img className='img-pink-border' width={32} src={'https://letsconnect.azureedge.net/members/' + user.AvatarUrl} />
                                                    </div>
                                                    <div className="col-3 cbg">
                                                        {user.CallWithText}
                                                    </div>
                                                    <div className="col-6">
                                                        <img width={18} src="/assets/img/call.png" />
                                                    </div>
                                                    <div className="col-2 text-center">
                                                        <button style={buttonStyle} onClick={() => deleteData(user, 2)} className='btn-theme-default me-2'>Delete</button>
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    }
                                    {iscallData && !callData.length ? <div className="text-center mt-3">There are no records</div> : null}
                                </>
                            </Tab>
                            <Tab eventKey="KeyChats" title="Chats">
                                <>
                                    <div className='sec-title text-center mb-3'>Chats</div>
                                    <div className="header-table">
                                        <div className="row">
                                            <div className="col-1">
                                            </div>
                                            <div className="col-3">
                                                Name
                                            </div>
                                            <div className="col-6">
                                                Chat
                                            </div>
                                            <div className="col-2 text-center">
                                                Action
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        chatsData.map((user: any, k: any) => {
                                            return <div key={k} className='row-table'>
                                                <div className="row align-items-center">
                                                    <div className="col-1 text-center">
                                                        <img className='img-pink-border' width={32} src={'https://letsconnect.azureedge.net/members/' + user.ImageFileUrl} />
                                                    </div>
                                                    <div className="col-3 cbg">
                                                        {user.Name}
                                                    </div>
                                                    <div className="col-6">
                                                        <img width={18} src="/assets/img/chat.png" />
                                                    </div>
                                                    <div className="col-2 text-center">
                                                        <button style={buttonStyle} onClick={() => deleteData(user, 3)} className='btn-theme-default me-2'>Delete</button>
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    }
                                    {ischatsData && !chatsData.length ? <div className="text-center mt-3">There are no records</div> : null}
                                </>
                            </Tab>
                            <Tab eventKey="KeyContacts" title="Contacts">
                                <>
                                    <div className='sec-title text-center mb-3'>Contacts</div>
                                    <div className="header-table">
                                        <div className="row">
                                            <div className="col-1">
                                            </div>
                                            <div className="col-2">
                                                First Name
                                            </div>
                                            <div className="col-6">
                                                Last Name
                                            </div>
                                            <div className="col-3 text-center">
                                                Action
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        contatsData.map((user: any, k: any) => {
                                            return <div key={k} className='row-table'>
                                                <div className="row align-items-center">
                                                    <div className="col-1 text-center">
                                                        <img className='img-pink-border' width={32} src={'https://letsconnect.azureedge.net/members/' + user.MemberId_Contact_ProfilePicture} />
                                                    </div>
                                                    <div className="col-2 cbg">
                                                        {user.FirstName}
                                                    </div>
                                                    <div className="col-6 cbg">
                                                        {user.LastName}
                                                    </div>
                                                    <div className="col-3 text-center">
                                                        <button style={buttonStyle} className='btn-theme-default me-2'>Edit</button>
                                                        <button style={buttonStyle} onClick={() => deleteData(user, 4)} className='btn-theme-default me-2'>Delete</button>
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    }
                                    {iscontatsData && !contatsData.length ? <div className="text-center mt-3">There are no records</div> : null}
                                </>
                            </Tab>
                            <Tab eventKey="KeyFavorites" title="Favorites">
                                <>
                                    <div className='sec-title text-center mb-3'>Favorities</div>
                                    <div className="header-table">
                                        <div className="row">
                                            <div className="col-1">
                                            </div>
                                            <div className="col-9">
                                                Name
                                            </div>
                                            <div className="col-2 text-center">
                                                Action
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        favoritesData.map((user: any, k: any) => {
                                            return <div key={k} className='row-table'>
                                                <div className="row align-items-center">
                                                    <div className="col-1 text-center">
                                                        <img className='img-pink-border' width={32} src={'https://letsconnect.azureedge.net/members/' + user.MemberId_Favorites_ProfilePicture} />
                                                    </div>
                                                    <div className="col-9 cbg">
                                                        {user.FullName}
                                                    </div>
                                                    <div className="col-2 text-center">
                                                        <button style={buttonStyle} onClick={() => deleteData(user, 5)} className='btn-theme-default me-2'>Delete</button>
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    }
                                    {isfavoritesData && !favoritesData.length ? <div className="text-center mt-3">There are no records</div> : null}
                                </>
                            </Tab>
                            <Tab eventKey="KeyConnections" title="Connections">
                                <div className='sec-title text-center mb-3'>Connections</div>
                                <div className="accepted">
                                    <div className='me-2'>Accepted</div>
                                    <div className='me-2'><img className='d-flex' width={16} src="/assets/img/checkcolor.png" /></div>
                                    <div className='w-100'><span className='g-line w-100'></span></div>
                                </div>
                                <div className="row mt-3">
                                    <>
                                        {
                                            acceptedData.map((user: any, k: any) => {
                                                return <div key={k} className="col-4">
                                                    <div className='con-box'>
                                                        <div className='first-half'>
                                                            <img className='d-flex' width={20} src="/assets/img/check.png" />
                                                        </div>
                                                        <div className='second-half'>
                                                            <div className='con-user'>
                                                                <img className='img-pink-border' width={32} src={'https://letsconnect.azureedge.net/members/' + user.MemberId_Primary_ProfilePicture} />
                                                            </div>
                                                            <div className='con-name'>
                                                                {user.MemberId_Primary_FullName}
                                                            </div>
                                                            <div className='con-arrow'>
                                                                <img width={7} src="/assets/img/arrowg.png" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                        }
                                        {isacceptedData && !acceptedData.length ? <div className="text-center mt-3">There are no records</div> : null}
                                    </>
                                </div>
                                <div className="accepted mt-4">
                                    <div className='me-2'>Pending</div>
                                    <div className='me-2'><img className='d-flex' width={16} src="/assets/img/dotcolor.png" /></div>
                                    <div className='w-100'><span className='g-line w-100'></span></div>
                                </div>
                                <div className="row mt-3">
                                    <>
                                        {
                                            pendingData.map((user: any, k: any) => {
                                                return <div key={k} className="col-4">
                                                    <div className='con-box'>
                                                        <div className='first-half'>
                                                            <img className='d-flex' width={20} src="/assets/img/dots.png" />
                                                        </div>
                                                        <div className='second-half'>
                                                            <div className='con-user'>
                                                                <img className='img-pink-border' width={32} src={'https://letsconnect.azureedge.net/members/' + user.MemberId_Primary_ProfilePicture} />
                                                            </div>
                                                            <div className='con-name'>
                                                                You have send Invitation to  {user.MemberId_Primary_FullName}
                                                            </div>
                                                            <div className='con-new'>
                                                                New
                                                            </div>
                                                            <div className='con-arrow'>
                                                                <img width={7} src="/assets/img/arrowg.png" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                        }
                                        {isacceptedData && !pendingData.length ? <div className="text-center mt-3">There are no records</div> : null}
                                    </>
                                </div>
                            </Tab>
                            <Tab eventKey="KeyNotifications" title="Notifications">
                                <div className='sec-title text-center mb-3'>Notifications</div>
                                <div className="row">
                                    {
                                        notificationData.map((user, k) => {
                                            return <div key={k} className="col-4 mb-3 ps-0">
                                                <div className='con-content'>
                                                    <div className='con-note'>
                                                        <div className='con-icon'>
                                                            {user.notificationTag == 'Invitation' && <img className='d-flex' width={20} src="/assets/img/envp.png" />}
                                                            {user.notificationTag == 'CallHistory' && <img className='d-flex' width={20} src="/assets/img/callhis.png" />}
                                                            {user.notificationTag == 'Chat' && <img className='d-flex' width={20} src="/assets/img/note.png" />}
                                                            {user.notificationTag == 'Date' && <img className='d-flex' width={20} src="/assets/img/date.png" />}
                                                        </div>
                                                        <div className='con-note-name'>
                                                            {user.notificationTag == 'Invitation' && <span className='text-dark'>Invitation</span>}
                                                            {user.notificationTag == 'CallHistory' && <span className='text-dark'>Call History</span>}
                                                            {user.notificationTag == 'Chat' && <span className='text-dark'>Chat with {user?.fromName}</span>}
                                                            {user.notificationTag == 'Date' && <span className='text-dark'>Date Schedule</span>}
                                                        </div>
                                                        <div className='con-note-end'>
                                                            <span className='cpink me-1'>{user.NotificationDateTime}</span> <img width={7} src="/assets/img/arrowg.png" />
                                                        </div>
                                                    </div>
                                                    <div className='f12 con-info'>
                                                        {user.NotificationText}
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                                {isNotificationData && !notificationData.length ? <div className="text-center mt-3">There are no any new notification</div> : null}
                            </Tab>
                            <Tab eventKey="KeyChangePassword" title="Change Password">
                                <div className="row">
                                    <div className={(settings.fieldTypes == 'Grid') ? "col-12" : "col-6"} style={{ marginTop: (settings.fieldTypes == 'Grid') ? "0px" : "10px" }}>
                                        <JkcInput type={'password'} name={"Password"} settings={{ ...settings, value: password, label: "Password" }} onChangeEvent={(e: any) => { setPassword(e.target.value) }}></JkcInput>
                                    </div>
                                    <div className={(settings.fieldTypes == 'Grid') ? "col-12" : "col-6"} style={{ marginTop: (settings.fieldTypes == 'Grid') ? "0px" : "10px" }}>
                                        <JkcInput type={'password'} name={"ConfirmPassword"} settings={{ ...settings, value: confirmPassword, label: "Confirm Password" }} onChangeEvent={(e: any) => { setConfirmPassword(e.target.value) }}></JkcInput>
                                    </div>
                                    <div className="col-12 text-end mt-3" style={{ marginTop: (settings.fieldTypes == 'Grid') ? "0px" : "10px" }}>
                                        <button onClick={updatePasswordData} style={buttonStyle} className='btn-theme-default me-2'>Save</button>
                                    </div>
                                </div>
                            </Tab>

                            {/* <Tab eventKey="KeyProfile" title="Profile">
                                <div className='sec-title text-center mb-3'>Profile Picture</div>
                                <div className='text-center my-4 crop-upload'>
                                    <JkCropper imagePreview={profilePicture} cropShape='round' guid={''}
                                        onChangeCropper={async ({ fileCropped, fileCroppedBase64 }: any) => {
                                            await uploadFile(fileCropped, fileCroppedBase64);
                                        }} />
                                </div>
                                <div className='sec-title text-center mb-3'>Profile Video</div>
                                <div className='text-center mt-4 position-relative'>
                                    <div className='mb-4'>
                                        <button onClick={triggerInvokedFromParent} style={buttonStyle} className='btn-theme-default me-2'>Upload Video</button>
                                    </div>
                                    { (profileVideo && reloadVideo) ? <video width="320" controls>
                                        <source src={profileVideo} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video> : null }
                                    <input style={{display: "none"}} ref={inputRef} accept="video/mp4,video/x-m4v,video/*" type="file" name='fileUploadVx' id="fileUploadVx" onChange={onChangeVideo} />
                                </div>
                            </Tab> */}
                        </Tabs>
                    </div>
                    {Id &&
                        <div className="col-md-4" style={{ textAlign: 'center' }}>
                            <div className='sec-title text-center mb-0'>Profile Picture</div>
                            <div className='text-center my-4 crop-upload position-relative'>
                                <JkCropper imagePreview={profilePicture} cropShape='rect' aspectRadio={1} guid={''}
                                    onChangeCropper={async ({ fileCropped, fileCroppedBase64 }: any) => {
                                        await uploadFile(fileCropped, fileCroppedBase64);
                                    }} />
                                {imageLoader ? <div className='loader-icon'>Uploading...</div> : null}
                            </div>
                            {/* <>
                            <form>
                                <input type="file" className='btn-theme-default m-2' name='fileImage' onChange={handleFile}/>
                                <button type='button' className='btn-theme-default m-2' onClick={handleUpload} style={BUTTON_STYLE}>
                                    Upload photo
                                </button>
                            </form>
                        </> */}

                            <div className='sec-title text-center mt-3'>
                                Profile Video
                                <button onClick={triggerInvokedFromParent} style={buttonStyle} className='btn-theme-default me-2 float-end'>Upload Video</button>
                            </div>
                            <div className='text-center mt-2 position-relative'>
                                {(profileVideo && reloadVideo) ? <video width="320" controls>
                                    <source src={profileVideo} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video> : null}
                                <input style={{ display: "none" }} ref={inputRef} accept="video/mp4,video/x-m4v,video/*" type="file" name='fileUploadVx' id="fileUploadVx" onChange={onChangeVideo} />
                                {videoLoader ? <div className='loader-icon-icon'>Uploading...</div> : null}
                            </div>
                        </div>
                    }
                </div>
                <Modal show={isShow} onHide={() => setShow(false)} centered>
                    <Modal.Header style={modelStyle.header} closeButton>
                        <Modal.Title style={modelStyle.title}>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={modelStyle.body}><p className='mb-0'>Are you sure you want to {typeId === 7 ? 'block' : 'delete'} this item?</p></Modal.Body>
                    <Modal.Footer style={modelStyle.footer}>
                        <Button style={modelStyle.button} variant="secondary" onClick={() => setShow(false)}>
                            Close
                        </Button>
                        <Button style={modelStyle.button} variant="danger" onClick={() => handleDelete()}>
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </LoadingOverlay>
    )
};

export default MemberDetail