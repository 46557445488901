import axios from "axios";
import { AUTH_AUTHENTICATE, BASE_API, BASE_URL, LOGIN, MODELS, RETRIEVE, SERVICES } from "./RouteConst";

export const addAuthToHeader = (type = 'ADMIN') => {
  const token = getAuthToken(type);
  // const token = "14FE34B2-9547-43F5-A57C-F0DC7DE81AA9";
  return {
    headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
  }
}

export const addAuthToHeaderFormData = (type = 'ADMIN') => {
  const token = getAuthToken(type)
  // const token = "14FE34B2-9547-43F5-A57C-F0DC7DE81AA9";
  return {
    headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' },
  }
}

export const getAuthToken = (type: string = 'ADMIN') => {
  if (type === "APPLICATION") {
    // let appData = localStorage.getItem('Application');
    // if (appData) {
    //   const { auth } = JSON.parse(appData);
    //   return auth['AccessToken'];
    // } else {
    //   return null;
    // }
    return "14FE34B2-9547-43F5-A57C-F0DC7DE81AA9";
  } else {
    let appData = localStorage.getItem('User');
    if (appData) {
      const result = JSON.parse(appData);
      return result[`${type}_API_KEY`]
    } else {
      return null;
    }
  }
}

export const logoutVerifyErrors = (status: any) => {
  if (status === 400 || status === 401 || status === 403 || status === 404) {
    localStorage.clear()
    window.location.reload();
  }
}

export const login = async ({
  accessToken,
  setRedirectToReferrer = null,
  UserName,
  Password,
}: any) => {
  try {
    return await loginAdminUser(
      { AccessToken: accessToken, UserName, Password },
      setRedirectToReferrer,
    )
  } catch (error: any) {
    return { status: false, message: "Something went wrong." }
    // throw loginError(error)
  }
}

const loginAdminUser = async (
  { UserName, Password }: any,
  setRedirectToReferrer: any,
) => {
  try {
    const data = await loginApplication({ UserName, Password })
    if (data) {
      // let jsondata = {
      //   Auth: { ...data },
      //   MS_API_KEY: 'AccessToken',
      //   ADMIN_API_KEY: data.Token.tokenString,
      //   APPLICATION_API_KEY: 'appToken',
      // }
      localStorage.setItem('AccessToken', data.AccessToken);
      localStorage.setItem('AdminToken', '14FE34B2-9547-43F5-A57C-F0DC7DE81AA9');
      localStorage.setItem('User', JSON.stringify({Auth: {User: data}} ));
      setRedirectToReferrer && setRedirectToReferrer(true)
      fetchAppData();

      return { status: true, message: "Success" }
    } else {
      return { status: false, message: "Invalid Username and Password" }
    }
  } catch (error: any) {
    return { status: false, message: "Something went wrong." }
  }
}

const loginApplication = async ({ UserName = '', Password = '' }) => {
  try {
    const { data } = await axios.post(
      `https://api.letsconnect123.com/Services/v2/Login/Authenticate`,
      {
        UserName:UserName,
        Password:Password,
        TokenType: "users"
      },
      {
        headers: {
          Authorization: `Bearer 14FE34B2-9547-43F5-A57C-F0DC7DE81AA9`,
          AppName: 'dbAdmin',
          'Content-Type': 'application/json'
        },
      },
    )
    if (data.UserInfo) {
      return data.UserInfo
    } else {
      return null
    }
    // const { data } = await axios.post(
    //   `${BASE_URL}Data/Execute`,
    //   {
    //     "QueryOrProcedureName": "MEMBER_Authenticate",
    //     "DbName": "DbLetsConnect",
    //     "Params": [{
    //       "Name": "UserName",
    //       "Value": UserName
    //     },
    //     {
    //       "Name": "Password",
    //       "Value": Password
    //     }
    //     ]
    //   },
    //   {
    //     headers: {
    //       Authorization: `Bearer 14FE34B2-9547-43F5-A57C-F0DC7DE81AA9`,
    //     }
    //   },
    // )
    // return data.data[0]
  } catch (error: any) {
    throw new Error(error.message)
  }
}

let location = window.location.href;
const fetchAppData = async () => {
  try {
    const execData = await axios.post(
      `${BASE_API}${SERVICES}/v1/data/execute`,
      {
        DbName: 'DbAdmin',
        QueryOrProcedureName: "access.APPLICATIONS_Retrieve",
        Params: [
          {
            Name: "UrlRoot", Value: 'Letsconnect'
          }
        ],
      },
      { ...addAuthAccessTokenToHeader() },
    )

    const { data } = await axios.post(
      `${BASE_API}${SERVICES}${MODELS}/${RETRIEVE}`,
      {
        Model: 'Applications',
        Id: execData.data.data[0].Id,
        Params: [],
      },
      { ...addAuthAccessTokenToHeader() },
    )

    let jsonObj = { auth: data.data.RetrieveInfo[0] };
    localStorage.setItem('Application', JSON.stringify(jsonObj));

  } catch (error: any) {
    console.log(error)
    const status = error.response.status
    logoutVerifyErrors(status)
  }
}

export const addAuthAccessTokenToHeader = () => {
  const token = getAccessToken()
  return {
    headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
  }
}

export const getAccessToken = () => {
  let parseData = localStorage.getItem('User');
  if (parseData) {
    const { Auth } = JSON.parse(parseData);
    const accessToken = Auth.User.AccessToken
    return accessToken
  }
  return null;
}