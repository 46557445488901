import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";
import { BASE_API, UPLOAD_DOWNLOAD_API, SERVICES, WIDGETS } from "../../../shared/services/RouteConst";

function Header() {
  const [userName, setUserName] = useState("");
  const [avatar, setAvatar] = useState("");

  const getData = () => {
    let parseData = localStorage.getItem("User");
    if (parseData) {
      const { Auth } = JSON.parse(parseData);
      let Name = Auth.User.FirstName + " " + Auth.User.LastName;
      setUserName(Name);
      setAvatar(Auth.User.AvatarGuid);
    }
  };

  const logout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  useEffect(() => {
    getData();
  }, []);

  const replaceImage = (error: any) => {
    error.target.src = "/assets/img/avatar.jpg";
  };

  return (
    <Navbar className="nav-bar custom-navbar" expand="lg">
      <Container fluid={true}>
        <Navbar.Brand as={Link} to={"/dashboard"} style={{color: "#fff", marginRight: 30}}>
          <img width={50} src="/assets/img/logo.png"></img>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to={"/dashboard"}>
              Dashboard
            </Nav.Link>
            <Nav.Link as={Link} to={"/Admin/Member/Search"}>
              Members
            </Nav.Link>
            {/* <Nav.Link as={Link} to={"/Admin/Advertiser/Search"}>
              Advertisers
            </Nav.Link> */}
            {/* <NavDropdown title="System Admin" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to={"/Admin/Menus/Search"}>
                Menus
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to={"/Admin/Users/Search"}>
                Users
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to={"/Admin/Roles/Search"}>
                Roles
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to={"/Admin/Lookups/Search"}>
                Lookups
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to={"/Admin/Applications/Search"}>
                Applications
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to={"/Admin/Models/Search"}>
                Models
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to={"/Admin/Properties/Search"}>
                Properties
              </NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text className="p-0 me-2">
              {avatar ? (
                <img
                  height={30}
                  src={`${UPLOAD_DOWNLOAD_API}${SERVICES}${WIDGETS}/Files/Download/${
                    avatar || ""
                  }`}
                  onError={replaceImage}
                  className="user-avtar"
                  alt="Admin Panel"
                />
              ) : (
				<span className="user-avtar"></span>
              )}
            </Navbar.Text>
            <NavDropdown
              style={{
                marginRight: "20px"
              }}
			  align="end"
              title={userName}
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item as={Link} to={"/"}>Logout</NavDropdown.Item>
            </NavDropdown>
          </Navbar.Collapse>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
