import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CustomTable from '../../components/CustomTable';
import DeleteModal from './../../modals/DeleteModal';
import TransactionsTable from "../../components/datatable/TransactionsTable";

export const defaultDatatableProps = {
    apiHeaders: { 'Authorization': 'bearer 14FE34B2-9547-43F5-A57C-F0DC7DE81AA9', 'AppName': 'dbAdmin' },
    modelName: 'Advertiser',
}

const AdvertiserSearch = ({
    createUrl,
    editUrl,
    deleteUrl,
    addButtonStyle,
    addButtonTitle = 'Add',
    pageTitle = 'Advertisers',
    pageTitleStyle,
    headerStyle = { display: 'flex', flexDirection: "row", justifyContent: 'space-between' },
    modelStyle = {},
    datatableProps = {}
}: any) => {

    const navigate = useNavigate();
    const [payload, setPayLoad] = React.useState({});
    const [isShow, setShow] = React.useState(false);
    const [refreshTable, setRefreshTable] = React.useState(false);
    const handleActionButtonClick = (row: any, action: string) => {
        if (action === 'edit') {
            navigate(editUrl + row.MEMBER_ADS_ID);
        }
        if (action === 'delete') {
            setPayLoad({
                "DbName": "DbLetsConnect",
                "QueryOrProcedureName": "MEMBER_ADS_Delete",
                "Params": [{
                    "Name": "Id",
                    "Value": row.MEMBER_ADS_ID
                }]
            });
            setShow(true);
        }
    };
    const handelResponse = () => {
        setRefreshTable(false);
    };

    const handleRowClick = (row: any) => {
        navigate(editUrl + row.MEMBER_ADS_ID);
    }

    return (
        <div className="p-3">
            <div style={headerStyle}>
                <h6 style={pageTitleStyle} className='mb-0'>{pageTitle}</h6>
                <Link to={createUrl} style={addButtonStyle} className='btn btn-theme-default btn-sm'>
                    {addButtonTitle}
                </Link>
            </div>
            {/* <TransactionsTable
              apiUrl="https://api.letsconnect123.com/Services/Data/ExecuteOld"
              apiHeaders={{
                Authorization: `bearer 14FE34B2-9547-43F5-A57C-F0DC7DE81AA9`,
              }}
              additionalBodyParams={{DbName: "DbLetsConnect", QueryOrProcedureName: "MEMBER_ListAdmin"}}
              tableColumns={[{
                name: "First Name",
                selector: (row: any) => row.FirstName,
                width: "50%",
                sortable: true,
                // style: LEFT_ALIGN,
              },
              {
                name: "Last Name",
                center: false,
                width: "40%",
                selector: (row: any) => row.LastName,
            
                // style: LEFT_ALIGN,
              }]}
              enableSearch={true}
              rowsPerPage={25}
            //   handleAction={handleAction}
              refreshTable={refreshTable}
            /> */}
            <CustomTable {...defaultDatatableProps} {...datatableProps} handleRowClick={handleRowClick} handelResponse={handelResponse} refreshTable={refreshTable} handleActionButtonClick={handleActionButtonClick} />
            {isShow &&
                <DeleteModal
                    payload={payload}
                    apiUrl={deleteUrl}
                    apiHeaders={datatableProps.apiHeaders ? datatableProps.apiHeaders : defaultDatatableProps.apiHeaders}
                    isOpen={isShow}
                    callBack={(e: boolean) => {
                        if (e) {
                            setShow(false);
                            setRefreshTable(true);
                        } else {
                            setShow(false);
                        }
                    }}
                    modelName={defaultDatatableProps.modelName}
                    modelStyle={modelStyle}
                />}
        </div>
    )
}


export default AdvertiserSearch;
