import React, { useState, useRef, useMemo, useEffect } from 'react';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './cropper.css';

import blankProfile from '../../images/blank-profile.jpg'
import { BUTTON_STYLE } from '../../shared/services/Constants';
export interface cInputProps {
    height?: number;
    width?: any;
    cropShape?: string,
    onChangeCropper: ({
        originalFile,
        fileCropped,
        fileCroppedBase64,
    }: {
        originalFile?: any
        fileCropped?: any
        fileCroppedBase64?: any
    }) => void
    maxSizeMib?: number
    guid: any
    imagePreview?: any;
    triggerUpload?: any
    isButtonHidden?: boolean
    cropBoxHeight?: number
    cropBoxWidth?: any
    buttonBackGroundColor?: string
    aspectRadio?: number 
    initialAspectRadio?: number 
};

export const JkCropper: React.FC<cInputProps> = ({
    cropShape = 'rect',
    onChangeCropper,
    maxSizeMib = 2,
    height = '100%',
    width = 300,
    guid,
    triggerUpload,
    isButtonHidden = false,
    cropBoxHeight = 10,
    cropBoxWidth = 10,
    imagePreview,
    buttonBackGroundColor = "#ccc",
    aspectRadio = null,
    initialAspectRadio = null
  }) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const inputRef = useRef<any>(null);
    const [image, setImage] = useState("");
    const [cropData, setCropData] = useState("#");
    const [cropper, setCropper] = useState<any>();
    const fileRef = useRef()

    let buttonCss: React.CSSProperties = {
        backgroundColor: "#4c92e9",
        border: "1px solid #4c92e9",
        color: "white",
        padding: "5px 25px",
        textAlign: "center",
        textDecoration: "none",
        display: "inline-block",
        fontSize: "14px",
        marginTop: 10
    }

    const onChange = (e: any) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result as any);
            setIsOpen(true)
        };
        reader.readAsDataURL(files[0]);
    };

    const b64toBlob = async (dataURI:any) => {
        const base64file = await fetch(dataURI)
        const blobFile = await base64file.blob()
        return blobFile
    }

    const getRoundedCanvas = (sourceCanvas: any): HTMLCanvasElement => {
        const canvas = document.createElement('canvas')
        const context: any = canvas.getContext('2d')
        const width = sourceCanvas.width
        const height = sourceCanvas.height
        canvas.width = width
        canvas.height = height
        context.imageSmoothingEnabled = true
        context.drawImage(sourceCanvas, 0, 0, width, height)
        context.globalCompositeOperation = 'destination-in'
        context.beginPath()
        context.ellipse(width / 2, height / 2, width / 2, height / 2, 0, 0, 2 * Math.PI)
        context.fill()
        return canvas
    }

      
    const getCropData = async () => {
        if (typeof cropper !== 'undefined') {
            let cropperCanvas = cropper.getCroppedCanvas()
            setCropData(cropperCanvas.toDataURL())
            const fileCropped = await b64toBlob(cropperCanvas.toDataURL())
            onChangeCropper({
                fileCropped,
                originalFile: fileRef.current,
                fileCroppedBase64: cropperCanvas.toDataURL(),
            })
            setIsOpen(false)
        }
    }

    const triggerInvokedFromParent = () => {
        inputRef?.current?.click();
      };

    // useEffect(() => {
    //     triggerInvokedFromParent();
    //   }, [triggerUpload]);

    
    return (
        <div>
            <div style={{display: "block", width: width,height: height, borderRadius: (cropShape === 'round') ? "0px" : "0px", position: "relative"}} onClick={triggerInvokedFromParent}>
                <input style={{height: "100%", width: "100%", visibility: "hidden", display: 'none'}} ref={inputRef} type="file" name='fileUploadCx' id="fileUploadCx" onChange={onChange} />
                <div style={{
                    marginBottom: '1rem',
                    display: 'absolute',
                    left: '305px'
                }}>
                    <button style={BUTTON_STYLE} className='btn-theme-default me-2'>Upload photo</button>
                </div>
            {    
                (imagePreview) && 
                <>
                    <img src={imagePreview} style={{width: '100%', height: "100%"}} />  
                </>
            }
            </div>
            <Modal size="lg" backdrop="static" keyboard={false} show={modalIsOpen} onHide={() => setIsOpen(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Crop</Modal.Title>
                </Modal.Header>
                <Modal.Body className={'crop-'+cropShape}>
                    <Cropper
                        src={image}
                        zoomable={false}
                        minCropBoxHeight={cropBoxHeight}
                        minCropBoxWidth={cropBoxWidth}
                        background={false}
                        responsive={true}
                        cropBoxResizable={true}
                        checkOrientation={false}
                        onInitialized={(instance) => {
                            setCropper(instance);
                        }}
                        initialAspectRatio={initialAspectRadio}
                        aspectRatio={aspectRadio}
                        guides={true}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setIsOpen(false)}>Close</Button>
                    <Button variant="primary" onClick={getCropData}>Crop</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
export default JkCropper;