const Footer = () => {
    return (
        <header className="pc-header" style={{background: '#303030', minHeight: '40px', bottom: '0px', left: 0, zIndex: 9999, position: 'fixed', width: '100%'}}>
		    <div className="header-wrapper pt-2" style={{minHeight: '40px', alignItems: 'center'}}>
                <span className='ps-3' style={{color: '#fff', fontSize: 12}}> Admin Panel &#169; 2023 v1.0.0</span>
            </div>
        </header>
    )
}

export default Footer;