import { BASE_API, UPLOAD_DOWNLOAD_API, SERVICES, MODELS, LIST, DELETE, BASE_URL } from '../shared/services/RouteConst';
import DashboardComponent from '../template/pages/dashboard/Dashboard';
import { ADMIN_PAGES_COLUMNS, DATATABLE_PROPS, BUTTON_STYLE, DETAIL_PAGES_STYLE } from "../utils/DatatableProps";
// import {
//     ApplicationSearch,
//     ApplicationDetail,
//     MenuSearch,
//     UserSearch,
//     RoleSearch,
//     LookupSearch,
//     ModelSearch,
//     PropertieSearch,
//     MenuDetail,
//     UserDetail,
//     RoleDetail,
//     LookupDetail,
//     ModelDetail,
//     PropertieDetail,
// } from 'jkc-input';
import MemberSearch from '../pages/Members/Search';
import MemberDetail from '../pages/Members/Detail';
import AdvertiserSearch from '../pages/Advertisers/Search';
import AdvertiserDetail from '../pages/Advertisers/Detail';

const AdminRoutes = [
    { path: 'dashboard', component: <DashboardComponent /> },
    {
        path: 'Admin/Member/Search', component: <MemberSearch
            createUrl={'/Admin/Member/New'}
            editUrl={'/Admin/Member/Detail/'}
            deleteUrl={`${BASE_URL}Data/ExecuteOld`}
            addButtonStyle={DATATABLE_PROPS.ADD_BUTTON_STYLE}
            modelStyle={{ button: BUTTON_STYLE }}
            datatableProps={{
                extraParams: { RemoveModel: true, DbName: 'DbLetsConnect', QueryOrProcedureName: 'MEMBER_ListAdmin' },
                apiUrl: `${BASE_URL}Data/ExecuteOld`,
                tableColumns: ADMIN_PAGES_COLUMNS.members,
                rowsPerPage: DATATABLE_PROPS.ROW_PER_PAGE,
                paginationStyles: DATATABLE_PROPS.PAGINATEION_STYLE
            }} />
    },
    {
        path: 'Admin/Member/New', component: <MemberDetail BASE_API={BASE_API}
            UPLOAD_DOWNLOAD_API={UPLOAD_DOWNLOAD_API}
            fieldTypes={'Grid'}
            buttonStyle={DETAIL_PAGES_STYLE.BUTTON_STYLE}
            tabStyle={DETAIL_PAGES_STYLE.TAB_STYLE}
            modelStyle={{ button: BUTTON_STYLE }}
            customStyle={DETAIL_PAGES_STYLE.CUSTOM_STYLE} />
    },
    {
        path: 'Admin/Member/Detail/:Id', component: <MemberDetail BASE_API={BASE_API}
            UPLOAD_DOWNLOAD_API={UPLOAD_DOWNLOAD_API}
            fieldTypes={'Grid'}
            buttonStyle={DETAIL_PAGES_STYLE.BUTTON_STYLE}
            tabStyle={DETAIL_PAGES_STYLE.TAB_STYLE}
            modelStyle={{ button: BUTTON_STYLE }}
            customStyle={DETAIL_PAGES_STYLE.CUSTOM_STYLE} />
    },
    {
        path: 'Admin/Advertiser/Search', component: <AdvertiserSearch
            createUrl={'/Admin/Advertiser/New'}
            editUrl={'/Admin/Advertiser/Detail/'}
            deleteUrl={`${BASE_URL}Data/ExecuteOld`}
            addButtonStyle={DATATABLE_PROPS.ADD_BUTTON_STYLE}
            modelStyle={{ button: BUTTON_STYLE }}
            datatableProps={{
                extraParams: { RemoveModel: true, DbName: 'DbLetsConnect', QueryOrProcedureName: 'MEMBER_ADS_List' },
                apiUrl: `${BASE_URL}Data/ExecuteOld`,
                tableColumns: ADMIN_PAGES_COLUMNS.advertisers,
                rowsPerPage: DATATABLE_PROPS.ROW_PER_PAGE,
                paginationStyles: DATATABLE_PROPS.PAGINATEION_STYLE
            }} />
    },
    {
        path: 'Admin/Advertiser/New', component: <AdvertiserDetail BASE_API={BASE_API}
            UPLOAD_DOWNLOAD_API={UPLOAD_DOWNLOAD_API}
            fieldTypes={'Grid'}
            buttonStyle={DETAIL_PAGES_STYLE.BUTTON_STYLE}
            tabStyle={DETAIL_PAGES_STYLE.TAB_STYLE}
            modelStyle={{ button: BUTTON_STYLE }}
            customStyle={DETAIL_PAGES_STYLE.CUSTOM_STYLE} />
    },
    {
        path: 'Admin/Advertiser/Detail/:Id', component: <AdvertiserDetail BASE_API={BASE_API}
            UPLOAD_DOWNLOAD_API={UPLOAD_DOWNLOAD_API}
            fieldTypes={'Grid'}
            buttonStyle={DETAIL_PAGES_STYLE.BUTTON_STYLE}
            tabStyle={DETAIL_PAGES_STYLE.TAB_STYLE}
            modelStyle={{ button: BUTTON_STYLE }}
            customStyle={DETAIL_PAGES_STYLE.CUSTOM_STYLE} />
    },
    // {
    //     path: 'Admin/Menus/Search', component: <MenuSearch
    //         createUrl={'/Admin/Menus/New'}
    //         editUrl={'/Admin/Menus/Detail/'}
    //         deleteUrl={`${BASE_URL}Data/ExecuteOld`}
    //         addButtonStyle={DATATABLE_PROPS.ADD_BUTTON_STYLE}
    //         modelStyle={{ button: BUTTON_STYLE }}
    //         datatableProps={{
    //             apiUrl: `${BASE_API}${SERVICES}${MODELS}${LIST}`,
    //             tableColumns: ADMIN_PAGES_COLUMNS.menu,
    //             rowsPerPage: DATATABLE_PROPS.ROW_PER_PAGE,
    //             paginationStyles: DATATABLE_PROPS.PAGINATEION_STYLE
    //         }} />
    // },
    // {
    //     path: 'Admin/Users/Search', component: <UserSearch
    //         createUrl={'/Admin/Users/New'}
    //         editUrl={'/Admin/Users/Detail/'}
    //         deleteUrl={`${BASE_API}${SERVICES}${MODELS}/${DELETE}`}
    //         addButtonStyle={DATATABLE_PROPS.ADD_BUTTON_STYLE}
    //         modelStyle={{ button: BUTTON_STYLE }}
    //         datatableProps={{
    //             apiUrl: `${BASE_API}${SERVICES}${MODELS}${LIST}`,
    //             tableColumns: ADMIN_PAGES_COLUMNS.user,
    //             rowsPerPage: DATATABLE_PROPS.ROW_PER_PAGE,
    //             paginationStyles: DATATABLE_PROPS.PAGINATEION_STYLE
    //         }}
    //     />
    // },
    // {
    //     path: 'Admin/Roles/Search', component: <RoleSearch
    //         createUrl={'/Admin/Roles/New'}
    //         editUrl={'/Admin/Roles/Detail/'}
    //         deleteUrl={`${BASE_API}${SERVICES}${MODELS}/${DELETE}`}
    //         addButtonStyle={DATATABLE_PROPS.ADD_BUTTON_STYLE}
    //         modelStyle={{ button: BUTTON_STYLE }}
    //         datatableProps={{
    //             apiUrl: `${BASE_API}${SERVICES}${MODELS}${LIST}`,
    //             tableColumns: ADMIN_PAGES_COLUMNS.role,
    //             rowsPerPage: DATATABLE_PROPS.ROW_PER_PAGE,
    //             paginationStyles: DATATABLE_PROPS.PAGINATEION_STYLE
    //         }} />
    // },
    // {
    //     path: 'Admin/Lookups/Search', component: <LookupSearch
    //         createUrl={'/Admin/Lookups/New'}
    //         editUrl={'/Admin/Lookups/Detail/'}
    //         deleteUrl={`${BASE_API}${SERVICES}${MODELS}/${DELETE}`}
    //         addButtonStyle={DATATABLE_PROPS.ADD_BUTTON_STYLE}
    //         modelStyle={{ button: BUTTON_STYLE }}
    //         datatableProps={{
    //             apiUrl: `${BASE_API}${SERVICES}${MODELS}${LIST}`,
    //             tableColumns: ADMIN_PAGES_COLUMNS.lookup,
    //             rowsPerPage: DATATABLE_PROPS.ROW_PER_PAGE,
    //             paginationStyles: DATATABLE_PROPS.PAGINATEION_STYLE
    //         }} />
    // },
    // {
    //     path: 'Admin/Applications/Search', component: <ApplicationSearch
    //         createUrl={'/Admin/Applications/New'}
    //         editUrl={'/Admin/Applications/Detail/'}
    //         deleteUrl={`${BASE_API}${SERVICES}${MODELS}/${DELETE}`}
    //         addButtonStyle={DATATABLE_PROPS.ADD_BUTTON_STYLE}
    //         modelStyle={{ button: BUTTON_STYLE }}
    //         datatableProps={{
    //             apiUrl: `${BASE_API}${SERVICES}${MODELS}${LIST}`,
    //             tableColumns: ADMIN_PAGES_COLUMNS.applications,
    //             rowsPerPage: DATATABLE_PROPS.ROW_PER_PAGE,
    //             paginationStyles: DATATABLE_PROPS.PAGINATEION_STYLE
    //         }} />
    // },
    // {
    //     path: 'Admin/Models/Search', component: <ModelSearch
    //         createUrl={'/Admin/Models/New'}
    //         editUrl={'/Admin/Models/Detail/'}
    //         deleteUrl={`${BASE_API}${SERVICES}${MODELS}/${DELETE}`}
    //         addButtonStyle={DATATABLE_PROPS.ADD_BUTTON_STYLE}
    //         modelStyle={{ button: BUTTON_STYLE }}
    //         datatableProps={{
    //             apiUrl: `${BASE_API}${SERVICES}${MODELS}${LIST}`,
    //             tableColumns: ADMIN_PAGES_COLUMNS.model,
    //             rowsPerPage: DATATABLE_PROPS.ROW_PER_PAGE,
    //             paginationStyles: DATATABLE_PROPS.PAGINATEION_STYLE
    //         }} />
    // },
    // {
    //     path: 'Admin/Properties/Search', component: <PropertieSearch
    //         createUrl={'/Admin/Properties/New'}
    //         editUrl={'/Admin/Properties/Detail/'}
    //         deleteUrl={`${BASE_API}${SERVICES}${MODELS}/${DELETE}`}
    //         addButtonStyle={DATATABLE_PROPS.ADD_BUTTON_STYLE}
    //         modelStyle={{ button: BUTTON_STYLE }}
    //         datatableProps={{
    //             apiUrl: `${BASE_API}${SERVICES}${MODELS}${LIST}`,
    //             tableColumns: ADMIN_PAGES_COLUMNS.properties,
    //             rowsPerPage: DATATABLE_PROPS.ROW_PER_PAGE,
    //             paginationStyles: DATATABLE_PROPS.PAGINATEION_STYLE
    //         }} />
    // },
    // {
    //     path: 'Admin/Applications/New', component: <ApplicationDetail BASE_API={BASE_API}
    //         UPLOAD_DOWNLOAD_API={UPLOAD_DOWNLOAD_API}
    //         fieldTypes={'Grid'}
    //         buttonStyle={DETAIL_PAGES_STYLE.BUTTON_STYLE}
    //         tabStyle={DETAIL_PAGES_STYLE.TAB_STYLE}
    //         customStyle={DETAIL_PAGES_STYLE.CUSTOM_STYLE} />
    // },
    // {
    //     path: 'Admin/Applications/Detail/:Id', component: <ApplicationDetail BASE_API={BASE_API}
    //         UPLOAD_DOWNLOAD_API={UPLOAD_DOWNLOAD_API}
    //         fieldTypes={'Grid'}
    //         buttonStyle={DETAIL_PAGES_STYLE.BUTTON_STYLE}
    //         tabStyle={DETAIL_PAGES_STYLE.TAB_STYLE}
    //         customStyle={DETAIL_PAGES_STYLE.CUSTOM_STYLE} />
    // },
    // {
    //     path: 'Admin/Menus/New', component: <MenuDetail
    //         BASE_API={BASE_API}
    //         UPLOAD_DOWNLOAD_API={UPLOAD_DOWNLOAD_API}
    //         fieldTypes={'Grid'}
    //         buttonStyle={DETAIL_PAGES_STYLE.BUTTON_STYLE}
    //         tabStyle={DETAIL_PAGES_STYLE.TAB_STYLE}
    //         customStyle={DETAIL_PAGES_STYLE.CUSTOM_STYLE}
    //         modelStyle={{ button: BUTTON_STYLE }}
    //         datatableProps={{
    //             apiUrl: `${BASE_API}${SERVICES}${MODELS}${LIST}`,
    //             tableColumns: ADMIN_PAGES_COLUMNS.subMenu,
    //             rowsPerPage: DATATABLE_PROPS.ROW_PER_PAGE,
    //             paginationStyles: DATATABLE_PROPS.PAGINATEION_STYLE
    //         }} />
    // },
    // {
    //     path: 'Admin/Users/New', component: <UserDetail BASE_API={BASE_API}
    //         UPLOAD_DOWNLOAD_API={UPLOAD_DOWNLOAD_API}
    //         fieldTypes={'Grid'}
    //         buttonStyle={DETAIL_PAGES_STYLE.BUTTON_STYLE}
    //         tabStyle={DETAIL_PAGES_STYLE.TAB_STYLE}
    //         customStyle={DETAIL_PAGES_STYLE.CUSTOM_STYLE} />
    // },
    // {
    //     path: 'Admin/Roles/New', component: <RoleDetail BASE_API={BASE_API}
    //         UPLOAD_DOWNLOAD_API={UPLOAD_DOWNLOAD_API}
    //         fieldTypes={'Grid'}
    //         buttonStyle={DETAIL_PAGES_STYLE.BUTTON_STYLE}
    //         tabStyle={DETAIL_PAGES_STYLE.TAB_STYLE}
    //         customStyle={DETAIL_PAGES_STYLE.CUSTOM_STYLE} />
    // },
    // {
    //     path: 'Admin/Lookups/New', component: <LookupDetail
    //         BASE_API={BASE_API}
    //         UPLOAD_DOWNLOAD_API={UPLOAD_DOWNLOAD_API}
    //         fieldTypes={'Grid'}
    //         buttonStyle={DETAIL_PAGES_STYLE.BUTTON_STYLE}
    //         tabStyle={DETAIL_PAGES_STYLE.TAB_STYLE}
    //         customStyle={DETAIL_PAGES_STYLE.CUSTOM_STYLE}
    //         modelStyle={{ button: BUTTON_STYLE }}
    //         datatableProps={{
    //             apiUrl: `${BASE_API}${SERVICES}${MODELS}${LIST}`,
    //             tableColumns: ADMIN_PAGES_COLUMNS.lookupTypes,
    //             rowsPerPage: DATATABLE_PROPS.ROW_PER_PAGE,
    //             paginationStyles: DATATABLE_PROPS.PAGINATEION_STYLE
    //         }} />
    // },
    // {
    //     path: 'Admin/Models/New', component: <ModelDetail BASE_API={BASE_API}
    //         UPLOAD_DOWNLOAD_API={UPLOAD_DOWNLOAD_API}
    //         fieldTypes={'Grid'}
    //         buttonStyle={DETAIL_PAGES_STYLE.BUTTON_STYLE}
    //         tabStyle={DETAIL_PAGES_STYLE.TAB_STYLE}
    //         customStyle={DETAIL_PAGES_STYLE.CUSTOM_STYLE} />
    // },
    // {
    //     path: 'Admin/Properties/New', component: <PropertieDetail BASE_API={BASE_API}
    //         UPLOAD_DOWNLOAD_API={UPLOAD_DOWNLOAD_API}
    //         fieldTypes={'Grid'}
    //         buttonStyle={DETAIL_PAGES_STYLE.BUTTON_STYLE}
    //         tabStyle={DETAIL_PAGES_STYLE.TAB_STYLE}
    //         customStyle={DETAIL_PAGES_STYLE.CUSTOM_STYLE} />
    // },
    // {
    //     path: 'Admin/Menus/Detail/:Id', component: <MenuDetail
    //         UPLOAD_DOWNLOAD_API={UPLOAD_DOWNLOAD_API}
    //         fieldTypes={'Grid'}
    //         BASE_API={BASE_API}
    //         buttonStyle={DETAIL_PAGES_STYLE.BUTTON_STYLE}
    //         tabStyle={DETAIL_PAGES_STYLE.TAB_STYLE}
    //         customStyle={DETAIL_PAGES_STYLE.CUSTOM_STYLE}
    //         modelStyle={{ button: BUTTON_STYLE }}
    //         datatableProps={{
    //             apiUrl: `${BASE_API}${SERVICES}${MODELS}${LIST}`,
    //             tableColumns: ADMIN_PAGES_COLUMNS.subMenu,
    //             rowsPerPage: DATATABLE_PROPS.ROW_PER_PAGE,
    //             paginationStyles: DATATABLE_PROPS.PAGINATEION_STYLE
    //         }} />
    // },
    // {
    //     path: 'Admin/Users/Detail/:Id', component: <UserDetail BASE_API={BASE_API}
    //         UPLOAD_DOWNLOAD_API={UPLOAD_DOWNLOAD_API}
    //         fieldTypes={'Grid'}
    //         buttonStyle={DETAIL_PAGES_STYLE.BUTTON_STYLE}
    //         tabStyle={DETAIL_PAGES_STYLE.TAB_STYLE}
    //         customStyle={DETAIL_PAGES_STYLE.CUSTOM_STYLE} />
    // },
    // {
    //     path: 'Admin/Roles/Detail/:Id', component: <RoleDetail BASE_API={BASE_API}
    //         UPLOAD_DOWNLOAD_API={UPLOAD_DOWNLOAD_API}
    //         fieldTypes={'Grid'}
    //         buttonStyle={DETAIL_PAGES_STYLE.BUTTON_STYLE}
    //         tabStyle={DETAIL_PAGES_STYLE.TAB_STYLE}
    //         customStyle={DETAIL_PAGES_STYLE.CUSTOM_STYLE} />
    // },
    // {
    //     path: 'Admin/Lookups/Detail/:Id', component: <LookupDetail
    //         BASE_API={BASE_API}
    //         UPLOAD_DOWNLOAD_API={UPLOAD_DOWNLOAD_API}
    //         fieldTypes={'Grid'}
    //         buttonStyle={DETAIL_PAGES_STYLE.BUTTON_STYLE}
    //         tabStyle={DETAIL_PAGES_STYLE.TAB_STYLE}
    //         customStyle={DETAIL_PAGES_STYLE.CUSTOM_STYLE}
    //         modelStyle={{ button: BUTTON_STYLE }}
    //         datatableProps={{
    //             apiUrl: `${BASE_API}${SERVICES}${MODELS}${LIST}`,
    //             tableColumns: ADMIN_PAGES_COLUMNS.lookupTypes,
    //             rowsPerPage: DATATABLE_PROPS.ROW_PER_PAGE,
    //             paginationStyles: DATATABLE_PROPS.PAGINATEION_STYLE
    //         }} />
    // },
    // {
    //     path: 'Admin/Models/Detail/:Id', component: <ModelDetail BASE_API={BASE_API}
    //         UPLOAD_DOWNLOAD_API={UPLOAD_DOWNLOAD_API}
    //         fieldTypes={'Grid'}
    //         buttonStyle={DETAIL_PAGES_STYLE.BUTTON_STYLE}
    //         tabStyle={DETAIL_PAGES_STYLE.TAB_STYLE}
    //         customStyle={DETAIL_PAGES_STYLE.CUSTOM_STYLE} />
    // },
    // {
    //     path: 'Admin/Properties/Detail/:Id', component: <PropertieDetail BASE_API={BASE_API}
    //         UPLOAD_DOWNLOAD_API={UPLOAD_DOWNLOAD_API}
    //         fieldTypes={'Grid'}
    //         buttonStyle={DETAIL_PAGES_STYLE.BUTTON_STYLE}
    //         tabStyle={DETAIL_PAGES_STYLE.TAB_STYLE}
    //         customStyle={DETAIL_PAGES_STYLE.CUSTOM_STYLE} />
    // },
];

export default AdminRoutes;