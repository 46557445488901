import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { JkcInput } from '@softsmart/jkc-input';
import { Retrieve, Save, FileUpload } from '../../utils/ApiService';
import { ProgressBar } from 'react-loader-spinner'
import { toast } from 'react-toastify';
import { BUTTON_STYLE } from '../../shared/services/Constants';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import LoadingOverlay from 'react-loading-overlay-ts';
import '../../utils/pages.css';
import Select from 'react-select';


const AdvertiserDetail = ({
    buttonStyle,
    pageTitle = 'Advertisers Detail',
    fieldTypes = 'Normal', // Normal, Grid
    pageTitleStyle,
    headerStyle = { display: 'flex', flexDirection: "row", justifyContent: 'space-between' },
    tabStyle = {},
    customStyle = {},
    modelStyle = {},
}: any) => {

    const navigate = useNavigate();
    const { Id } = useParams();
    const inputRef = useRef<any>(null);
    const [imgPreview, setImgPreview] = useState(null);
    const [imageState, setImageState] = useState({ file: null })
    const [isUpload, setUpload] = useState(true);

    const [nextId, setNextId] = useState(false);
    const [prevId, setPrevId] = useState(false);
    const [isActive, setActive] = useState(false);

    const [imageLoader, setImageLoader] = useState(false);
    const [videoLoader, setVideoLoader] = useState(false);
    const [reloadVideo, setReloadVideo] = useState(true);

    const [activeKey, setActiveKey] = useState('typeInfo');
    const [searchTxt, setSearchTxt] = useState('');
    const [AdvertisersData, setAdvertisersData] = useState([]);
    const [profileVideo, setProfileVideo] = useState('');
    const [isShow, setShow] = React.useState(false);
    const [payload, setPayLoad] = React.useState({});
    const [typeId, setTypeId] = React.useState(0);

    const buttonPostiion = [{
        label: "Left",
        value: "Left",
    }, {
        label: "Right",
        value: "Right",
    }, {
        label: "Center",
        value: "Center",
    }]

    const [userData, setUserData] = useState({
        Id: Id || '0',
        Name: "",
        Duration: 0,
        Url: "",
        Price: 0,
        BusinessName: "",
        ContactPersonName: "",
        Phone: "",
        Email: "",
        Address: "",
        SuiteOrApt: "",
        Address2: "",
        State: "",
        Zipcode: "",
        TypeOfAd: "",
        ButtonPlacement: "",
        ButtonText: "",
        ButtonTextColor: "",
        ButtonColor: ""
    });

    const tabRef = useCallback((node: any) => {
        if (tabStyle.borderColor && node) {
            node.style.setProperty("--nav-tab-border-color", "#f5f5f5");
        }
        if (tabStyle.activeColor && node) {
            node.style.setProperty("--nav-tab-active-text-color", tabStyle.borderColor);
        }
        if (tabStyle.inactiveColor && node) {
            node.style.setProperty("--nav-tab-inactive-text-color", tabStyle.inactiveColor);
        }
        if (tabStyle.fontSize && node) {
            node.style.setProperty("--nav-tab-font-size", tabStyle.fontSize);
        }
        if (tabStyle.padding && node) {
            node.style.setProperty("--nav-tab-padding", tabStyle.padding);
        }
    }, []);

    let inputStyle = {
        borderColor: "#DEE2E6",
        borderRadius: '4px',
        padding: "0px 7px 0px 7px",
        height: '40px',
        fontSize: "14px",
        background: 'transparent',
        color: "#747474"
    }

    let labelStyle = {
        color: "#212529",
        paddingLeft: '7px',
        fontSize: "13px",
        fontWeight: "500"
    }

    if (customStyle && customStyle.inputStyle) {
        inputStyle = customStyle.inputStyle;
    }

    if (customStyle && customStyle.labelStyle) {
        labelStyle = customStyle.labelStyle;
    }

    const [settings, setSettings] = useState<any>({
        label: "",
        value: "",
        className: "",
        placeholder: "",
        mask: "(999)-999-9999",
        maskChar: "#",
        maskPlaceholder: "#",
        alwaysShowMask: true,
        iconShowHideToggle: false,
        isNormalLabel: true,
        hideLabel: false,
        inputStyle: inputStyle,
        labelStyle: labelStyle,
        fieldTypes: fieldTypes,
        inline: true,
        json: [
            {
                name: "Male",
                value: "Male",
            },
            {
                name: "Female",
                value: "Female",
            }
        ]
    });

    const [settings1, setSettings1] = useState({
        label: '',
        value: '',
        prefix: '$',
        step: 1,
        disableGroupSeparators: true,
        className: '',
        inputStyle: inputStyle,
        labelStyle: labelStyle,
        isNormalLabel: true,
    });

    const viewProfile = async (r: any) => {
        searchAdvertiser("")
        setAdvertisersData([]);
        navigate(`/Admin/Advertiser/Detail/${r.MEMBER_ADS_ID}`);
    }

    const deleteAdvertiser = async () => {
        const { data: bData }: any = await Retrieve({
            params: {
                "DbName": "DbLetsConnect",
                "QueryOrProcedureName": "MEMBER_ADS_Delete",
                Params: {
                    Id: userData.Id,
                }
            },
            apiUrl: `https://api.letsconnect123.com/Services/v2/Data/ExecuteOld`,
            token: localStorage.getItem('AdminToken')
        }).then(() => {
            toast.success("Profile Delete");
            navigate(`/Admin/Advertiser/Search`);
        })
        if (bData) {
            let mId = prevId ? prevId : nextId;
            if (!mId) {
                return
            }
            toast.success("Profile Delete");
            navigate(`/Admin/Advertiser/Detail/${mId}`);
        }
    };

    const handleFileClick = () => {
        inputRef.current.click();
    };

    const handleFile = (e: any) => {
        setUpload(false);
        let file = e.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setImgPreview(reader.result);
            setImageState({ file: file });
            setUpload(true);
            inputRef.current.value = "";
        };
    };

    const handleUpload = (e: any) => {
        if(imageState && imageState.file){
            uploadFile(imageState);    
        }
    };

    const uploadFile = async ({ file }) => {
        setImgPreview('');
        setUpload(false);
        let fileName = userData.Id + "-" + (Math.random() + 1).toString(36).substring(7) + ".jpg";
        const formData = new FormData()
        formData.append('Id', userData.Id || '')
        formData.append('File', file, fileName);
        formData.append('FileType', 'ads');
        formData.append('Description', 'application/jpg');
        const { data, error } = await FileUpload({
            apiUrl: `https://api.letsconnect123.com/Services/v2/LetsConnect/Files/UploadAds`,
            token: localStorage.getItem('AdminToken'),
            formData: formData
        });
        if (data) {
            setImgPreview('https://letsconnect.azureedge.net/members/' + data.FileInfo.FileUrl);
            setUpload(true);
        } else {
            setImgPreview('');
            setUpload(true);
        }
    };

    const viewData = async (Id: any) => {
        setReloadVideo(false);
        setActive(true);
        const { error: apiError, data: response }: any = await Retrieve({
            params: {
                DbName: "DbLetsConnect",
                QueryOrProcedureName: "MEMBER_ADS_Retrieve",
                Params: {
                    Id: Id
                }
            },
            apiUrl: `https://api.letsconnect123.com/Services/v2/Data/ExecuteOld`,
            token: localStorage.getItem('AdminToken')
        })
        let apiData = response;
        if (apiData[0].FilesId > 0) {
            setImgPreview('https://letsconnect.azureedge.net/members/' + apiData[0].AdPicture)
        } else {
            setImgPreview('');
        }
        if (apiData && apiData.length) {
            setUserData({
                Id: apiData[0].MEMBER_ADS_ID || "",
                Name: apiData[0].Name || "",
                Duration: apiData[0].Duration || 0,
                Url: apiData[0].Url || "",
                Price: apiData[0].Price || 0,
                BusinessName: apiData[0].BusinessName || "",
                ContactPersonName: apiData[0].ContactPersonName || "",
                Phone: apiData[0].Phone || "",
                Email: apiData[0].Email || "",
                Address: apiData[0].Address || "",
                SuiteOrApt: apiData[0].SuiteOrApt || "",
                TypeOfAd: apiData[0].TypeOfAd || "",
                Address2: apiData[0].Address2 || "",
                State: apiData[0].State || "",
                Zipcode: apiData[0].Zipcode || "",
                ButtonPlacement: apiData[0].ButtonPlacement || "",
                ButtonText: apiData[0].ButtonText || "",
                ButtonTextColor: apiData[0].ButtonTextColor || "",
                ButtonColor: apiData[0].ButtonColor || "",
            });
            setNextId(apiData[0].NextId)
            setPrevId(apiData[0].PrevId)
            if (apiData[0].FilesId_Profilevideo > 0) {
                setProfileVideo('https://letsconnect.azureedge.net/members/' + apiData[0].ProfileVideo)
            } else {
                setProfileVideo('');
            }
        }
        setActive(false);
        setReloadVideo(true);
    };

    const createEmptyRecord = async () => {
        const { data: saveData } = await Save({
            params: {
                DbName: "DbLetsConnect",
                QueryOrProcedureName: "MEMBER_ADS_Save",
                Params: userData,
            },
            apiUrl: `https://api.letsconnect123.com/Services/v2/Data/ExecuteOld`,
            token: localStorage.getItem('AdminToken')
        })
        if (saveData && saveData.length) {
            navigate(`/Admin/Advertiser/Detail/${saveData[0].MEMBER_ADS_ID}`);
        }
    }

    useEffect(() => {
        Id ? viewData(Id) : createEmptyRecord();
    }, [Id]);

    const onChangeButtonPlacement = (event: any) => {
        let v = null;
        if (event) {
            v = event.value;
        }
        setUserData({ ...userData, ButtonPlacement: v });
    }
    
    const onChangeColor = (value: any, name: any) => {
        setUserData({ ...userData, [name]: value })
    }

    const onChangeEvent = (event: any) => {
        const name = event.target.name;
        const value = event.target.value;
        setUserData({ ...userData, [name]: value })
    }

    const onChangePrice = (event) => {
        setUserData((prevState) => {
            return { ...prevState, Price: event }
        })
    }
    const saveData = async () => {
        userData.Price = parseInt(userData.Price.toString())
        setActive(true);
        const { data: saveData, message: saveMessage } = await Save({
            params: {
                DbName: "DbLetsConnect",
                QueryOrProcedureName: "MEMBER_ADS_Save",
                Params: userData
            },
            apiUrl: `https://api.letsconnect123.com/Services/v2/Data/ExecuteOld`,
            token: localStorage.getItem('AdminToken')
        })
        toast.success("Saved");
        navigate(`/Admin/Advertiser/Search`);
        if (saveData && saveData.length) {
            setUserData({ ...userData, Id: saveData[0].MEMBER_ADS_ID })
        }
        setActive(false);
    }

    const deleteData = async (obj: any, type: any) => {
        setShow(true);
        setPayLoad(obj);
        setTypeId(type);
    }

    const handleDelete = async () => {
        if (typeId === 6) {
            deleteAdvertiser();
        }
        setShow(false);
        setTypeId(0);
    }

    const onChangeVideo = async (e: any) => {
        setReloadVideo(false);
        setVideoLoader(true);
        let file = e.target.files[0];
        let fileName = userData.Id + "-" + file.name;
        let blobURL = URL.createObjectURL(file);
        const formData = new FormData()
        formData.append('Id', userData.Id || '')
        formData.append('File', file, fileName)
        formData.append('FileType', 'video');
        formData.append('Description', 'application/mp4');

        const { data, error } = await FileUpload({
            apiUrl: `https://api.letsconnect123.com/Services/LetsConnect/Files/Upload`,
            token: localStorage.getItem('AdminToken'),
            formData: formData
        })

        if (data && data.FileInfo) {
            setProfileVideo(blobURL);
            setTimeout(() => {
                setReloadVideo(true);
            }, 300)
        } else {
            toast.error("Error while uploading video");
            setTimeout(() => {
                setReloadVideo(true);
            }, 300)
        }
        setVideoLoader(false);
    };

    const searchAdvertiser = async (val: any) => {
        setSearchTxt(val);
        if (val && val.length > 2) {
            const { data: bData }: any = await Retrieve({
                params: {
                    DbName: "DbLetsConnect",
                    QueryOrProcedureName: "MEMBER_ADS_Retrieve",
                    Params: {
                        OrderBy: "FirstName",
                        OrderByDir: "ASC",
                        SearchString: val,
                        Page: 1,
                        PageSize: 10
                    }
                },
                apiUrl: `https://api.letsconnect123.com/Services/v2/Data/ExecuteOld`,
                token: localStorage.getItem('AdminToken')
            })
            if (bData && bData.length) {
                setAdvertisersData(bData);
            } else {
                setAdvertisersData([]);
            }
        } else {
            setAdvertisersData([]);
        }
    }

    return (
        <LoadingOverlay
            active={isActive}
            spinner
            text='Loading your content...'>
            <div className='p-3'>
                <div style={headerStyle}>
                    <div>
                        <h6 style={pageTitleStyle} className='mb-0'>{pageTitle}</h6>
                    </div>
                    <div className='position-relative'>
                        {(searchTxt && searchTxt.length > 2) ?
                            <div className='Advertiser-list'>
                                {AdvertisersData.length ?
                                    AdvertisersData.map((r, k) => {
                                        return <div onClick={() => viewProfile(r)} key={k} className='person'>{r.FirstName} {r.LastName}</div>
                                    }) : null}
                            </div> : null}
                    </div>
                    <div>
                        <button onClick={saveData} style={buttonStyle} className='btn-theme-default me-2'>Save</button>
                        <button style={buttonStyle} onClick={() => deleteData({}, 6)} className='btn-theme-default me-2'>Delete</button>
                        <Link to="/Admin/Advertiser/Search" style={buttonStyle} className='btn-theme-default me-2'>Back</Link>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md-8" ref={tabRef}>
                        <Tabs
                            defaultActiveKey="profile"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                            activeKey={activeKey}
                            onSelect={(k: any) => setActiveKey(k)}
                        >
                            <Tab eventKey="typeInfo" title="Detail">
                                <div className="ad_id_box">
                                    Advertiser Id : {userData.Id}
                                </div>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-2">
                                        <JkcInput type={'text'} name={"Name"} settings={{ ...settings1, value: userData.Name, label: "Ad Name" }} onChangeEvent={onChangeEvent}></JkcInput>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-2">
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                                <div className="row">
                                                    <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8 mb-2">
                                                        <JkcInput type={'text'} name={"Address"} settings={{ ...settings1, value: userData.Address, label: "Address" }} onChangeEvent={onChangeEvent}></JkcInput>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mb-2">
                                                        <JkcInput type={'text'} name={"SuiteOrApt"} settings={{ ...settings1, value: userData.SuiteOrApt, label: "Apt Or Suite" }} onChangeEvent={onChangeEvent}></JkcInput>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-2">
                                                        <JkcInput type={'text'} name={"Address2"} settings={{ ...settings1, value: userData.Address2, label: "Address 2" }} onChangeEvent={onChangeEvent}></JkcInput>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7 mb-2">
                                                        <JkcInput type={'text'} name={"State"} settings={{ ...settings1, value: userData.State, label: "State" }} onChangeEvent={onChangeEvent}></JkcInput>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 mb-2">
                                                        <JkcInput type={'text'} name={"Zipcode"} settings={{ ...settings1, value: userData.Zipcode, label: "Zip Code" }} onChangeEvent={onChangeEvent}></JkcInput>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-2">
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-2">
                                            <JkcInput type={'text'} name={"BusinessName"} settings={{ ...settings1, value: userData.BusinessName, label: "Business Name" }} onChangeEvent={onChangeEvent}></JkcInput>
                                            </div>
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-2">
                                            <JkcInput type={'text'} name={"ContactPersonName"} settings={{ ...settings1, value: userData.ContactPersonName, label: "Contact Person Name" }} onChangeEvent={onChangeEvent}></JkcInput>
                                            </div>
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-2">
                                            <JkcInput type={'mask'} name={"Phone"} settings={{ ...settings1, value: userData.Phone, label: "Phone" }} onChangeEvent={(e:any)=>{ setUserData((prevSate:any)=> ({...prevSate , Phone: e}) ) }}></JkcInput>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-2">
                                        <JkcInput type={'text'} name={"Email"} settings={{ ...settings1, value: userData.Email, label: "Email" }} onChangeEvent={onChangeEvent}></JkcInput>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-2">
                                        <JkcInput type={'number'} name={"Price"} settings={{ ...settings1, value: userData.Price, label: "Price" }} onChangeEvent={onChangePrice}></JkcInput>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-2">
                                        <JkcInput type={'text'} name={"Duration"} settings={{ ...settings1, value: userData.Duration, label: "Duration (months.)" }} onChangeEvent={onChangeEvent}></JkcInput>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-2">
                                        <JkcInput type={'text'} name={"TypeOfAd"} settings={{ ...settings1, value: userData.TypeOfAd, label: "Type Of Ad" }} onChangeEvent={onChangeEvent}></JkcInput>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-2">
                                        <JkcInput type={'select'} name={"ButtonPlacement"} settings={{ ...settings1, json: buttonPostiion, value: (userData.ButtonPlacement) ? buttonPostiion.find(e => e.value == userData.ButtonPlacement): null, label: "Button Placement" }} onChangeEvent={onChangeButtonPlacement}></JkcInput>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-2">
                                        <JkcInput type={'text'} name={"ButtonText"} settings={{ ...settings1, value: userData.ButtonText, label: "Button Text" }} onChangeEvent={onChangeEvent}></JkcInput>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-2">
                                        <JkcInput type={'color'} name={"ButtonTextColor"} settings={{ ...settings1, value: userData.ButtonTextColor, label: "Button Text Color" }} onChangeEvent={(e) => onChangeColor(e, 'ButtonTextColor')}></JkcInput>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-2">
                                        <JkcInput type={'color'} name={"ButtonColor"} settings={{ ...settings1, value: userData.ButtonColor, label: "Button Color" }} onChangeEvent={(e) => onChangeColor(e, 'ButtonColor')}></JkcInput>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9 mb-2">
                                        <JkcInput type={'text'} name={"Url"} settings={{ ...settings1, value: userData.Url, label: "Url" }} onChangeEvent={onChangeEvent}></JkcInput>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-2" style={{ marginTop: "21px" }}>
                                        <a href={userData.Url} target='_blank'>
                                            <button style={{ ...buttonStyle, height: '40px', width: '100%' }} className='btn-theme-default me-2'>Follow link</button>
                                        </a>
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                    <div className="col-md-4" style={{ textAlign: 'center' }}>
                        <div className='sec-title text-center mb-3'>Profile Picture</div>
                        <div className="image-upload" onClick={handleFileClick}>
                            {(!imgPreview && isUpload) && <h4 className='drag-text'><i className="fa-solid fa-upload me-2"></i>Upload Photo</h4>}
                            {(imgPreview && isUpload) &&
                                <img src={imgPreview} key={imgPreview} className='img-fluid' height={170} />
                            }
                            {!isUpload &&
                                <div className="progress-bar">
                                    <ProgressBar
                                        height="80"
                                        width="80"
                                        ariaLabel="progress-bar-loading"
                                        wrapperStyle={{}}
                                        wrapperClass="progress-bar-wrapper"
                                        borderColor='#F11286'
                                        barColor='#F11286'
                                    />
                                </div>
                            }
                        </div>
                        <button type='button' className='btn-theme-default m-2' onClick={handleUpload} style={BUTTON_STYLE}>
                            Upload photo
                        </button>
                        <input type="file" ref={inputRef} style={{ display: 'none' }} name='fileImage' onChange={handleFile} />
                    </div>
                </div>

                <Modal show={isShow} onHide={() => setShow(false)} centered>
                    <Modal.Header style={modelStyle.header} closeButton>
                        <Modal.Title style={modelStyle.title}>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={modelStyle.body}><p className='mb-0'>Are you sure you want to delete this item?</p></Modal.Body>
                    <Modal.Footer style={modelStyle.footer}>
                        <Button style={modelStyle.button} variant="secondary" onClick={() => setShow(false)}>
                            Close
                        </Button>
                        <Button style={modelStyle.button} variant="danger" onClick={() => handleDelete()}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </LoadingOverlay>
    )
};
export default AdvertiserDetail;