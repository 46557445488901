export const UPLOAD_DOWNLOAD_API = 'https://api.softsmartinc.com'
export const BASE_URL = "https://api.letsconnect123.com/Services/v1/";
export const BASE_API = 'https://api.letsconnect123.com'
export const MODELS = '/Models'
export const LIST = '/List'
export const WIDGETS = '/Widget'
export const SERVICES = '/Services'
export const EXECUTE = 'Execute'
export const DATA = '/Data'
export const SAVE = 'Save'
export const TOKENS = '/Token'
export const TOKENS_MODEL = 'Tokens'
export const USERS = 'Users'
export const RETRIEVE = 'Retrieve'
export const DELETE = 'Delete'
export const MENUS_MODEL = 'Menus'
export const LOGIN = 'Login'
export const AUTH_AUTHENTICATE = 'Authenticate'
